import { Classes } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { repeatLength } from '../utils/lengths';
import './entities.css';

interface EntityProps {
  entity: {
    id: number;
    length: number;
    repeats: Array<{
      repeatBegin: number;
      repeatEnd: number;
      repeatLeft: number;
      entityBegin: number;
      subtype?: string | null;
      orientation: string;
      name: string;
    }>;
  };
}

interface EntityLinkProps extends EntityProps {
  type?: 'link' | 'button';
}

const ENTITY_BAR_MIN_WIDTH = 5;

export function EntityBar(props: EntityProps) {
  const entityLength = props.entity.length;

  return (
    <div className="entity-bar entity-bar--inline entity-bar--on-link">
      {props.entity.repeats.map((repeat, index) => {
        let width = (100 * repeatLength(repeat)) / entityLength;
        if (width < ENTITY_BAR_MIN_WIDTH) {
          width = ENTITY_BAR_MIN_WIDTH;
        }

        const left = (100 * repeat.entityBegin) / entityLength;

        const css = ['entity-bar__item'];
        if (repeat.subtype) {
          css.push(`entity-bar__item--${repeat.subtype.toLowerCase()}`);
        }

        return (
          <div
            key={`${props.entity.id}-${index}`}
            className={css.join(' ')}
            style={{ width: `${width}%`, left: `${left}%` }}
          ></div>
        );
      })}
    </div>
  );
}

export function EntityButton(props: EntityProps) {
  return <EntityLink entity={props.entity} type="button" />;
}

export function EntityLink(props: EntityLinkProps) {
  const names = props.entity.repeats
    .map((repeat) => repeat.name)
    .sort()
    .filter((v, i, a) => a.indexOf(v) === i)
    .join(' ');

  let linkClassName: string;
  let spanClassName: string;

  if (props.type === 'button') {
    linkClassName = 'entity-button';
    spanClassName = `${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`;
  } else {
    linkClassName = 'entity-link';
    spanClassName = '';
  }

  return (
    <Link className={linkClassName} to={`/entities/${props.entity.id}`}>
      <EntityBar entity={props.entity} />

      <span className={spanClassName}>
        #{props.entity.id} ({names})
      </span>
    </Link>
  );
}
