import { Icon } from '@blueprintjs/core';
import { PropsWithChildren } from 'react';
import { externalLinkEvent } from '../app/tracking';
import './links.css';

interface ExternalLinkProps {
  href: string;
}

interface AccessionNumbersLinksProps {
  numbers?: string[] | null;
}

export function ExternalLink(props: PropsWithChildren<ExternalLinkProps>) {
  return (
    <a
      href={props.href}
      target="_blank"
      rel="noreferrer"
      className="link-with-icon no-wrap"
      onClick={(event) => {
        externalLinkEvent(event.currentTarget.href);
      }}
    >
      <Icon icon="document-open" />
      {props.children}
    </a>
  );
}

export function AccessionNumbersLinks(props: AccessionNumbersLinksProps) {
  if (!props.numbers) {
    return null;
  }

  return (
    <>
      {props.numbers.map((number) => (
        <ExternalLink key={number} href={`https://dfam.org/family/${number}`}>
          {number}
        </ExternalLink>
      ))}
    </>
  );
}
