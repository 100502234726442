import { SettingValues } from '../../settings';
import { booleanToParam, paramToBoolean } from '../../utils/searchParams';
import { BaseModule } from '../baseModule';
import { CytobandChart } from './chart';
import { CytobandController } from './controller';
import { GraphqlData, graphqlQuery, GraphqlVariables } from './graphql';

export const cytobandKey = 'cytoband';

export class CytobandModule extends BaseModule {
  initialize(): void {
    this.chartPosition = 2;
    this.controllerPosition = 3;

    this.eventBus.on('loadSearchParams', this.loadSearchParams.bind(this));
    this.eventBus.on('setSearchParams', this.setSearchParams.bind(this));
    this.eventBus.on('settingChanged', this.settingChanged.bind(this));
    this.eventBus.on('browserMounted', this.browserMounted.bind(this));
  }

  chartComponent() {
    return CytobandChart;
  }

  controllerComponent() {
    return CytobandController;
  }

  private async loadData(): Promise<void> {
    const { sequence } = this.setting.get('position');

    this.changeState('LOADING');

    return this.dataManager
      .load<GraphqlData, GraphqlVariables>(
        'cytoband',
        graphqlQuery,
        { sequence },
        (data) => data.cytoband,
      )
      .then(() => this.changeState('OK'))
      .catch(() => this.changeState('ERROR'));
  }

  private browserMounted() {
    this.loadDataIfNecessary();
  }

  private loadSearchParams(params: URLSearchParams) {
    const param = params.get('cytoband');

    this.setting.set('cytoband', paramToBoolean(param));
  }

  private setSearchParams(params: URLSearchParams) {
    const settingValue = this.setting.get('cytoband');

    params.set('cytoband', booleanToParam(settingValue));
  }

  private settingChanged(keys: (keyof SettingValues)[]) {
    if (keys.includes('position') || keys.includes(cytobandKey)) {
      this.loadDataIfNecessary();
    }
  }

  private loadDataIfNecessary() {
    const enabled = this.setting.get('cytoband');

    if (enabled) {
      this.loadData();
    } else {
      this.changeState('OK');
    }
  }
}
