import { gql } from '@apollo/client';
import { DataEdge, Entity, Repeat, Statistics } from '../../graphql';

export interface GraphqlData {
  statistics: Pick<
    Statistics,
    | 'elementsCount'
    | 'entitiesCount'
    | 'repeatsCount'
    | 'elementsCountsByType'
    | 'entitiesCountsByType'
    | 'repeatsCountsByType'
    | 'repeatsCountsByTypeAndSequence'
  >;
}

export const graphqlQuery = gql`
  query {
    statistics {
      elementsCount
      repeatsCount
      entitiesCount
      elementsCountsByType {
        type
        count
      }
      repeatsCountsByType {
        type
        count
      }
      entitiesCountsByType {
        type
        count
      }
      repeatsCountsByTypeAndSequence {
        type
        sequence
        count
      }
    }
  }
`;

export interface GraphqlEntitiesData {
  entities: DataEdge<
    Pick<Entity, 'id' | 'length' | 'repeatNames'> & {
      repeats: Pick<
        Repeat,
        | 'repeatBegin'
        | 'repeatEnd'
        | 'repeatLeft'
        | 'entityBegin'
        | 'subtype'
        | 'orientation'
        | 'name'
      >[];
    }
  >;
}

export const graphqlEntitiesQuery = gql`
  query {
    entities(
      filter: { type: "ERV" }
      orderBy: { fragmentationScore: DESC }
      pagination: { limit: 20, page: 1 }
    ) {
      edges {
        id
        length
        repeatNames
        repeats {
          repeatBegin
          repeatEnd
          repeatLeft
          entityBegin
          subtype
          orientation
          name
        }
      }
    }
  }
`;
