import { gql } from '@apollo/client';
import { DataEdge, EntitiesFilter, Entity, Pagination } from '../../graphql';

export interface GraphqlData {
  entities: DataEdge<
    Pick<
      Entity,
      | 'id'
      | 'cloudId'
      | 'repeatsLength'
      | 'gapsAbsolute'
      | 'gapsRelative'
      | 'type'
      | 'subtype'
      | 'schema'
      | 'sequence'
      | 'sequenceBegin'
      | 'sequenceEnd'
      | 'orientation'
      | 'length'
      | 'repeats'
    >
  >;
}

export type GraphqlVariables = EntitiesFilter & Pagination;

export const graphqlQuery = gql`
  query GetEntities(
    $search: String
    $sequence: String
    $type: String
    $subtype: String
    $sequenceBeginGte: Int
    $sequenceEndLte: Int
    $page: Int!
    $limit: Int!
  ) {
    entities(
      filter: {
        search: $search
        sequence: $sequence
        type: $type
        subtype: $subtype
        sequenceBeginGte: $sequenceBeginGte
        sequenceEndLte: $sequenceEndLte
      }
      pagination: { page: $page, limit: $limit }
    ) {
      pageInfo {
        count
        page
        limit
        offset
      }
      edges {
        id
        cloudId
        repeatsLength
        gapsAbsolute
        gapsRelative
        type
        subtype
        schema
        sequence
        sequenceBegin
        sequenceEnd
        orientation
        length
        repeats {
          id
          cloudId
          repeatBegin
          repeatEnd
          entityBegin
          orientation
          repeatLeft
          subtype
          name
        }
      }
    }
  }
`;
