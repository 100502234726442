import { debounce } from 'lodash';
import { Dispatch, ChangeEvent } from 'react';
import { trackCurrentLocation } from '../../app/tracking';
import { LIMIT } from '../../config';

type Items = Record<string, any>;
type ItemsKeys<T extends Items> = Extract<keyof T, string>;

export interface FormParams<T extends Items> {
  state: FormState<T>;
  dispatch: Dispatch<FormAction<ItemsKeys<T>>>;
}

export type FormAction<T extends keyof Items> =
  | { action: 'setValue'; key: T; value: string | number }
  | {
      action: 'setValues';
      values: Record<string, string | number | null | undefined>;
    }
  | { action: 'setPage'; page: number }
  | { action: 'reset' };

export type FormState<T extends Items> = Partial<T> & {
  page: number;
  limit: number;
  key?: number;
};

function stateToUrl<T extends Items>(
  urlKeys: ItemsKeys<T>[],
  state: FormState<T>,
) {
  const url = new URL(window.location.toString());
  url.search = '';

  urlKeys.forEach((key) => {
    const value = state[key];

    if (typeof value === 'string') {
      url.searchParams.set(key, value);
    } else if (typeof value === 'number') {
      url.searchParams.set(key, value.toString());
    }
  });

  window.history.pushState({}, '', url.toString());

  trackCurrentLocation();
}

export function formReducer<T extends Items>(urlKeys: ItemsKeys<T>[]) {
  return (state: FormState<T>, action: FormAction<ItemsKeys<T>>) => {
    switch (action.action) {
      case 'setValue':
        state = { ...state, [action.key]: action.value, page: 1 };
        break;

      case 'setValues':
        state = { ...state, ...action.values, page: 1 };
        break;

      case 'setPage':
        state = { ...state, page: action.page };
        break;

      case 'reset':
        state = { page: 1, limit: LIMIT } as FormState<T>;
    }

    state.key = Date.now();

    stateToUrl(urlKeys, state);

    return state;
  };
}

export function textInputChange<T extends string>(
  dispatch: Dispatch<FormAction<T>>,
  wait?: number,
) {
  const func = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    dispatch({
      action: 'setValue',
      key: event.target.name as T,
      value: event.target.value,
    });
  };

  if (wait) {
    return debounce(func, wait);
  } else {
    return func;
  }
}

export function numericInputChange<T extends string>(
  dispatch: Dispatch<FormAction<T>>,
  wait?: number,
) {
  const func = (
    valueAsNumber: number,
    _valueAsString: string,
    inputElement: HTMLInputElement | null,
  ) => {
    dispatch({
      action: 'setValue',
      key: inputElement?.name as T,
      value: valueAsNumber,
    });
  };

  if (wait) {
    return debounce(func, wait);
  } else {
    return func;
  }
}

export function pageChange<T extends string>(
  dispatch: Dispatch<FormAction<T>>,
) {
  return (page: number) =>
    dispatch({
      action: 'setPage',
      page,
    });
}

export function resetForm<T extends string>(dispatch: Dispatch<FormAction<T>>) {
  return () => dispatch({ action: 'reset' });
}
