import './cigar.css';

interface CigarProps {
  cigar: string;
}

export function Cigar(props: CigarProps) {
  const match = props.cigar.matchAll(/(\d+)([MIDNSHPX])/g);

  if (!match) {
    return <>{props.cigar}</>;
  }

  const result: JSX.Element[] = [];

  for (const [, count, op] of Array.from(match)) {
    result.push(
      <span
        className="cigar-string__item"
        style={{ color: `var(--cigar-${op})` }}
      >
        {count}
        {op}
      </span>
    );
  }

  return <>{result}</>;
}
