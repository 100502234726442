import { useContext } from 'react';
import { Group, Line, Text } from 'react-konva';
import { getCSS } from '../../../utils/css';
import { humanSize } from '../../../utils/parsers';
import { BrowserContext } from '../../browser';
import { ChartContext } from '../../chart';
import { useModuleStateReloader } from '../../utils/hooks';
import { SequenceTrackModule } from './module';
import './style.css';

const POSITIONS_HEIGHT = 14;
const TRACK_LINE_HEIGHT = 5;
const TRACK_LINE_SPACING = 50;
const TRACK_TEXT_SPACING = 150;

export function SequenceTrackChart() {
  useModuleStateReloader(SequenceTrackModule);

  const { setting } = useContext(BrowserContext);
  const { width, sequenceScale } = useContext(ChartContext);

  const position = setting.get('position');
  const numbers = [];
  const lines = [];

  for (let x = 0; x <= width; x += TRACK_TEXT_SPACING) {
    const positionValue = position.sequenceBegin + x / sequenceScale;

    numbers.push(
      <Text
        key={x}
        x={x}
        text={humanSize(positionValue, { fixed: 1 })}
        fill={getCSS('--entity-chart-sequence-track-color')}
      />,
    );
  }

  for (let x = 0; x <= width; x += TRACK_LINE_SPACING) {
    lines.push(
      <Line
        key={x}
        points={[x, 0, x, TRACK_LINE_HEIGHT]}
        stroke={getCSS('--entity-chart-sequence-track-color')}
        strokeWidth={1}
      />,
    );
  }

  lines.push(
    <Line
      key={'bottom-line'}
      points={[0, TRACK_LINE_HEIGHT, width, TRACK_LINE_HEIGHT]}
      stroke={getCSS('--entity-chart-sequence-track-color')}
      strokeWidth={1}
    />,
  );

  return (
    <Group name="componentGroup">
      {numbers}

      <Group y={POSITIONS_HEIGHT}>{lines}</Group>
    </Group>
  );
}
