import { BaseModule } from '../baseModule';
import { SequenceTrackChart } from './chart';

export class SequenceTrackModule extends BaseModule {
  initialize() {
    this.chartPosition = 6;
    this.changeState('OK');
  }

  chartComponent() {
    return SequenceTrackChart;
  }
}
