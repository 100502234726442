import { useContext, useState, useEffect } from 'react';
import { BrowserContext } from './browser';

export function Loader() {
  const { eventBus, modules } = useContext(BrowserContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const checkForLoading = () => {
      if (
        modules.some(
          (mod) => mod.state === 'INITIALIZED' || mod.state === 'LOADING',
        )
      ) {
        setLoading(true);
      } else {
        setLoading(false);
      }
    };

    eventBus.on('moduleStateChanged', checkForLoading);

    return () => {
      eventBus.off('moduleStateChanged', checkForLoading);
    };
  }, []);

  if (!loading) {
    return null;
  }

  return (
    <div className="browser-loader">
      <div className="browser-loader__content">
        <span className="browser-loader__text">Loading ...</span>
      </div>
    </div>
  );
}
