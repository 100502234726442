import { useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import { BarChart } from '../../charts/bar';
import { Box } from '../../components/box';
import { Card } from '../../components/card';
import { EntityButton } from '../../components/entities';
import { AccessionNumbersLinks } from '../../components/links';
import { Loading } from '../../components/loading';
import { ElementReferences } from '../../components/references';
import { TableDetails, TableDetailsData } from '../../components/tableDetails';
import { Title } from '../../components/title';
import { GraphqlElement, graphqlQuery } from './graphql';

const BEST_ENTITIES_COUNT = 10;

export function ElementPage() {
  const { id } = useParams<{ id: string }>();

  const { loading, error, data } = useQuery<
    GraphqlElement,
    {
      id: number;
      bestEntitiesCount: number;
    }
  >(graphqlQuery, {
    variables: {
      id: parseInt(id!),
      bestEntitiesCount: BEST_ENTITIES_COUNT,
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <p>Error {error.message}</p>;
  }

  if (!data?.element) {
    return <p>Error</p>;
  }

  const { element } = data;

  const tableData: TableDetailsData = [
    { label: 'Type', value: element.type },
    { label: 'Subtype', value: element.subtype },
    { label: 'Length', value: element.length },
    { label: 'Keywords', value: element.keywords?.join(', ') },
    {
      label: 'Accession numbers',
      value: <AccessionNumbersLinks numbers={element.accessionNumbers ?? []} />,
    },
    { label: 'Description', value: element.description },
    {
      label: 'Links',
      value: (
        <Link to={`/repeats?elementId=${element.id}`}>
          Repeats contains {element.name}
        </Link>
      ),
    },
    {
      label: '',
      value: (
        <Link to={`/entities?elementId=${element.id}`}>
          Entities contains {element.name}
        </Link>
      ),
    },
  ];

  return (
    <>
      <Title>Element: {element.name}</Title>

      <Box>
        <TableDetails data={tableData} />

        <div className="flex-columns flex-columns--20">
          <div>
            <strong>Comment</strong>
            <div>{element.comment}</div>
          </div>
          <div>
            <strong>References</strong>
            <ElementReferences references={element.references ?? []} />
          </div>
        </div>
      </Box>

      <Card header="Entity examples" className="margin-bottom-20">
        {element.bestEntities.map((entity) => (
          <EntityButton key={entity.id} entity={entity} />
        ))}
      </Card>

      <Card header="Counts per chromosomes">
        <BarChart
          labels={data.sequencesSizes.map(
            (sequenceSize) => sequenceSize.sequence
          )}
          data={data.element.repeatsDistribution.map((item) => ({
            label: item.sequence,
            value: item.count,
          }))}
        />
      </Card>
    </>
  );
}
