import { SettingValues } from '../../settings';
import { BaseModule } from '../baseModule';
import { GraphqlData, graphqlQuery, GraphqlVariables } from './graphql';

export const key = 'repeats';

export class RepeatsModule extends BaseModule {
  initialize() {
    this.eventBus.on('settingChanged', this.settingChanged.bind(this));
    this.eventBus.on('browserMounted', this.browserMounted.bind(this));
  }

  private browserMounted() {
    this.loadData();
  }

  private settingChanged(keys: (keyof SettingValues)[]) {
    if (keys.includes('position')) {
      this.loadData();
    }
  }

  private async loadData(): Promise<void> {
    const { sequence, sequenceBegin, sequenceEnd } =
      this.setting.get('position');

    this.changeState('LOADING');

    return this.dataManager
      .load<GraphqlData, GraphqlVariables>(
        key,
        graphqlQuery,
        {
          sequence,
          sequenceBeginGte: sequenceBegin,
          sequenceEndLte: sequenceEnd,
        },
        (data) => data.repeats.edges,
      )
      .then(() => this.changeState('OK'))
      .catch(() => this.changeState('ERROR'));
  }
}
