import { useContext } from 'react';
import { Group, Line, Rect, Text } from 'react-konva';
import { getCSS } from '../../../utils/css';
import { BrowserContext } from '../../browser';
import { ChartContext } from '../../chart';
import { useModuleStateReloader } from '../../utils/hooks';
import { convertSequencePosition, textWidth } from '../../utils/utils';
import './style.css';
import { Gene } from './graphql';
import { GenesModule } from './module';

const HEIGHT = 12;
const NAME_PADDING = 3;
const NAME_FONT_SIZE = 12;
const LEVEL_HEIGHT = HEIGHT + NAME_PADDING + NAME_FONT_SIZE + NAME_PADDING + 5;
const MARGIN = 10;

type BrowseCallback = (gene: Gene, x: number, width: number) => void;
type StringToNumber = Record<string, number>;

export function GenesChart() {
  useModuleStateReloader(GenesModule);

  const { setting, dataManager } = useContext(BrowserContext);
  const ctx = useContext(ChartContext);

  const genes = dataManager.get('genes');

  if (!setting.get('genes') || !genes) {
    return null;
  }

  const transcriptLevel: StringToNumber = {};
  const levelStack: number[] = [];
  const prevExons: StringToNumber = {};
  const geneElements: JSX.Element[] = [];
  const geneData = [...genes];

  const browseData = (type: string, callback: BrowseCallback) => {
    geneData.forEach((gene) => {
      if (gene.type !== type) {
        return;
      }

      const width = (gene.sequenceEnd - gene.sequenceBegin) * ctx.sequenceScale;
      const x = convertSequencePosition(
        gene.sequenceBegin,
        ctx.sequenceScale,
        setting.get('position'),
      );

      callback(gene, x, width);
    });
  };

  browseData('transcript', (gene, x, width) => {
    const x2 =
      x + Math.max(width, textWidth(gene.name, NAME_FONT_SIZE)) + MARGIN;

    let level: number;
    for (level = 0; level < levelStack.length; level++) {
      const levelDetail = levelStack[level];

      if (levelDetail < x) {
        break;
      }
    }

    transcriptLevel[gene.geneId] = level;
    levelStack[level] = x2;

    const nameTextX = x < 0 ? 0 : x;
    const nameTextY = level * LEVEL_HEIGHT + HEIGHT + NAME_PADDING;

    geneElements.push(
      <Text
        x={nameTextX}
        y={nameTextY}
        text={gene.name}
        fontSize={NAME_FONT_SIZE}
        fill={getCSS('--text-color')}
      />,
    );

    //
    // A small hack to render lines to indicate
    // that transcript is bigger than current view
    //
    if (x < 0) {
      geneData.push({
        id: 0,
        geneId: 'geneId',
        orientation: gene.orientation,
        sequence: gene.sequence,
        name: gene.name,
        type: 'exon',
        parentId: gene.geneId,
        sequenceBegin: gene.sequenceBegin,
        sequenceEnd: gene.sequenceBegin,
      });
    }

    if (x2 > ctx.width) {
      geneData.push({
        id: 0,
        geneId: 'geneId',
        orientation: gene.orientation,
        sequence: gene.sequence,
        name: gene.name,
        type: 'exon',
        parentId: gene.geneId,
        sequenceBegin: gene.sequenceEnd,
        sequenceEnd: gene.sequenceEnd,
      });
    }
  });

  // Must be done because of a little hack above
  geneData.sort((g1, g2) => g1.sequenceBegin - g2.sequenceEnd);

  browseData('exon', (gene, x, width) => {
    const y = transcriptLevel[gene.parentId!] * LEVEL_HEIGHT;

    if (!y) {
      return;
    }

    geneElements.push(
      <Rect
        x={x}
        y={y}
        width={width}
        height={HEIGHT}
        fill={getCSS('--entity-chart-exon-color')}
      />,
    );

    const prevExon = prevExons[gene.parentId!];

    if (prevExon) {
      const distance = x - prevExon;
      const middleX = prevExon + distance / 2;

      geneElements.push(
        <Line
          points={[prevExon, y + HEIGHT / 2, middleX, y, x, y + HEIGHT / 2]}
          stroke={getCSS('--entity-chart-exon-color')}
          strokeWidth={1}
        />,
      );
    }

    prevExons[gene.parentId!] = x + width;
  });

  browseData('CDS', (gene, x, width) => {
    const y = transcriptLevel[gene.parentId!] * LEVEL_HEIGHT;

    if (!y) {
      return;
    }

    geneElements.push(
      <Rect
        x={x}
        y={y}
        width={width}
        height={HEIGHT}
        fill={getCSS('--entity-chart-cds-color')}
      />,
    );
  });

  return <Group name="componentGroup">{geneElements}</Group>;
}
