import {
  Chart,
  ChartConfiguration,
  ChartData,
  LayoutPosition,
  LegendOptions,
} from 'chart.js';
import { DeepPartial } from 'chart.js/types/utils';
import { renderChart } from './base';
import { ChartSchema } from './schema';

export interface DataItem {
  label: string;
  value: number;
}

interface PieChartOptions {
  data: DataItem[];
  legend?: false | LayoutPosition;
  schema?: ChartSchema;
  title?: string;
}

export function PieChart(props: PieChartOptions) {
  return renderChart({
    title: props.title,
    draw: (context) => draw(context, props),
  });
}

function draw(context: CanvasRenderingContext2D, params: PieChartOptions) {
  const { data } = params;

  data.sort((item1, item2) => item2.value - item1.value);

  const chartData: ChartData<'doughnut'> = {
    labels: data.map((item) => item.label),
    datasets: [
      {
        data: data.map((item) => item.value),
      },
    ],
  };

  const legend: DeepPartial<LegendOptions<'doughnut'>> = {
    labels: {
      color: getComputedStyle(document.documentElement).getPropertyValue(
        '--text-color'
      ),
    },
  };

  if (params.legend) {
    legend.position = params.legend;
  } else {
    legend.display = false;
  }

  const configuration: ChartConfiguration<'doughnut'> = {
    type: 'doughnut',
    data: chartData,
    options: {
      plugins: {
        legend,
      },
      scales: {},
      elements: {
        arc: {
          borderColor: getComputedStyle(
            document.documentElement
          ).getPropertyValue('--background-color'),
        },
      },
    },
  };

  if (params.schema && configuration.data?.datasets) {
    const { schema } = params;

    configuration.data.datasets[0].backgroundColor = (context) => {
      if (context.chart.data.labels) {
        const label = (context.chart.data.labels as string[])[
          context.dataIndex
        ].toString();

        return schema(label);
      }

      return schema('unknown');
    };
  }

  return new Chart(context, configuration);
}
