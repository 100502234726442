import { gql } from '@apollo/client';
import { EntitiesEdge, SequenceSize, ValueStore } from '../graphql';

export interface GraphqlData {
  sequencesSizes: SequenceSize[];
  valueStore: ValueStore;
  entities: EntitiesEdge;
}

export const graphqlQuery = gql`
  query {
    sequencesSizes {
      sequence
      size
    }
    valueStore {
      elementTypes
      errorLevels {
        name
        value
      }
      introductionHtml
      name
      ref
      runAt
      shortName
      version
    }
    entities(
      orderBy: { fragmentationScore: DESC }
      pagination: { limit: 1, page: 1 }
    ) {
      edges {
        id
        cloudId
        name
        repeatNames
        type
        length
        orientation
        sequence
        sequenceEnd
        sequenceBegin
      }
    }
  }
`;
