import Konva from 'konva';
import { compact, sortBy } from 'lodash';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { Group, Layer, Rect, Stage } from 'react-konva';
import { ThemeContext } from '../theme';
import { getCSS } from '../utils/css';
import { BrowserContext } from './browser';

export interface ChartContextType {
  width: number;
  sequenceScale: number;
}

export const COMPONENTS_PADDING = 20;

export const ChartContext = createContext<ChartContextType>({
  width: 100,
  sequenceScale: 0,
});

export function Chart() {
  const [, setTimestamp] = useState(0);
  const browserContext = useContext(BrowserContext);
  const stageRef = useRef<Konva.Stage>(null);

  useEffect(() => {
    /**
     * A small hack
     *
     * Chart depends on the width of the .main container but
     * function can be called when it does not exist yes
     */
    const browserMounted = () => setTimestamp(Date.now());

    const downloadImage = () => {
      if (stageRef.current) {
        const link = document.createElement('a');
        link.download = document.title + '.png';
        link.href = stageRef.current.toDataURL({ pixelRatio: 2 });
        link.click();
      }
    };

    const offCallbacks: (() => void)[] = [];

    if (stageRef?.current) {
      const layers = stageRef?.current.getLayers();

      for (const layer of layers) {
        const setHeightCallback = () => {
          let y = COMPONENTS_PADDING;

          layer.find('.componentGroup').forEach((group) => {
            group.y(y);

            const { height } = group.getClientRect();
            y += Math.floor(height + COMPONENTS_PADDING);
          });

          layer.getStage().height(y + COMPONENTS_PADDING);
        };

        layer.on('beforeDraw', setHeightCallback);

        offCallbacks.push(() => layer.off('beforeDraw', setHeightCallback));
      }
    }

    browserContext.eventBus.on('browserMounted', browserMounted);
    browserContext.eventBus.on('downloadImage', downloadImage);

    return () => {
      browserContext.eventBus.off('browserMounted', browserMounted);
      browserContext.eventBus.off('downloadImage', downloadImage);

      for (const offCallback of offCallbacks) {
        offCallback();
      }
    };
  }, [stageRef]);

  const entity = browserContext.dataManager.get('entity');
  const position = browserContext.setting.get('position');
  const width = document.querySelector('.main')?.clientWidth || 100;
  const sequenceLength = position.sequenceEnd - position.sequenceBegin;
  const maxSequenceScale = width / sequenceLength;
  const consensusScale = entity ? width / entity.length : 999999999;

  // Scale repeats on sequence, consensus, sequence track
  const sequenceScale = Math.min(maxSequenceScale, consensusScale);

  const ctx: ChartContextType = {
    width,
    sequenceScale,
  };

  const chartComponents = compact(
    sortBy(browserContext.modules, (mod) => mod.chartPosition).map((mod) =>
      mod.chartComponent(),
    ),
  );

  return (
    <ThemeContext.Consumer>
      {() => (
        <Stage width={width} ref={stageRef}>
          <BrowserContext.Provider value={browserContext}>
            <ChartContext.Provider value={ctx}>
              <Layer>
                <Rect
                  x={0}
                  y={0}
                  width={width}
                  height={9999999}
                  fill={getCSS('--background-color')}
                />

                <Group>
                  {chartComponents.map((Component) => (
                    <Component key={Component.name} />
                  ))}
                </Group>
              </Layer>
            </ChartContext.Provider>
          </BrowserContext.Provider>
        </Stage>
      )}
    </ThemeContext.Consumer>
  );
}
