import * as React from 'react';
import { PropsWithChildren } from 'react';

interface ControlsProps {
  header: JSX.Element | string;
}

export function Controls(props: PropsWithChildren<ControlsProps>) {
  return (
    <div className="browser-controls">
      <div className="browser-controls__heading">{props.header}</div>
      {!!React.Children.count(props.children) && (
        <div className="browser-controls__content">{props.children}</div>
      )}
    </div>
  );
}
