import { Button, Checkbox, Classes, Switch } from '@blueprintjs/core';
import { countBy, pull } from 'lodash';
import { ChangeEvent, useContext } from 'react';
import { BrowserContext } from '../../browser';
import { Controls } from '../../components/controls';
import { useModuleStateReloader } from '../../utils/hooks';
import { EntitiesModule } from './module';

export function EntitiesController() {
  const [timestamp] = useModuleStateReloader(EntitiesModule);
  const { setting, eventBus, dataManager } = useContext(BrowserContext);

  const entities = dataManager.get('entities');

  if (!entities) {
    return (
      <Controls
        header={
          <Switch
            label="Entities"
            onChange={() => {
              setting.set('entities', ['erv']);
              eventBus.emit('settingChanged', ['entities']);
            }}
            defaultChecked={false}
          />
        }
      />
    );
  }

  const availableTypes = countBy(entities, 'type');

  const defaultChecked = (type: string) => {
    return setting.get('entities')?.includes(type.toLowerCase());
  };

  const uncheckAll = () => {
    setting.set('entities', []);
    eventBus.emit('settingChanged', ['entities']);
  };

  const checkAll = () => {
    setting.set(
      'entities',
      Object.keys(availableTypes).map((type) => type.toLowerCase()),
    );
    eventBus.emit('settingChanged', ['entities']);
  };

  const oneChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    const entities = setting.get('entities') || [];

    if (checked) {
      entities.push(value);
    } else {
      pull(entities, value);
    }

    setting.set('entities', entities);
    eventBus.emit('settingChanged', ['entities']);
  };

  const header = (
    <div className="flex-space-between">
      <span>Entities</span>

      <span>
        <Button icon="collapse-all" onClick={uncheckAll} minimal>
          Uncheck all
        </Button>

        <Button icon="expand-all" onClick={checkAll} minimal>
          Check all
        </Button>
      </span>
    </div>
  );

  return (
    <Controls key={timestamp} header={header}>
      {Object.entries(availableTypes).map(([type, count]) => {
        const label = (
          <>
            {type} <span className={Classes.TEXT_MUTED}>({count}x)</span>
          </>
        );

        return (
          <Checkbox
            key={type}
            labelElement={label}
            value={type.toLowerCase()}
            defaultChecked={defaultChecked(type)}
            onChange={oneChange}
            inline
          />
        );
      })}
    </Controls>
  );
}
