import { useQuery } from '@apollo/client';
import {
  Button,
  ControlGroup,
  FormGroup,
  HTMLSelect,
  HTMLTable,
  InputGroup,
  NumericInput,
} from '@blueprintjs/core';
import { useReducer } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '../../components/box';
import { Error } from '../../components/error';
import { Loading } from '../../components/loading';
import { NoDataToDisplay } from '../../components/noDataToDisplay';
import { Title } from '../../components/title';
import { DEFAULT_TYPE, LIMIT } from '../../config';
import { GlobalStore } from '../../globalStore';
import { SearchParams } from '../../utils/searchParams';
import { GraphqlData, graphqlQuery, GraphqlVariables } from './graphql';
import {
  FormParams,
  formReducer,
  FormState,
  numericInputChange,
  pageChange,
  textInputChange,
} from '../forms/formState';
import { Pagination } from '../forms/pagination';

const reducer = formReducer<GraphqlVariables>([
  'limit',
  'page',
  'minLength',
  'name',
  'type',
]);

export function ElementsPage() {
  const searchParams = new SearchParams();

  const initialState: FormState<GraphqlVariables> = {
    name: searchParams.getString('name'),
    type: searchParams.getString('type', DEFAULT_TYPE),
    minLength: searchParams.getInt('minLength', 0),
    page: searchParams.getInt('page', 1),
    limit: LIMIT,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <>
      <Form state={state} dispatch={dispatch} />
      <Table state={state} dispatch={dispatch} />
    </>
  );
}

function Form(props: FormParams<GraphqlVariables>) {
  return (
    <Box key={props.state.key}>
      <FormGroup
        helperText={`Enter at least 1 characters`}
        label="Search"
        labelFor="search-input"
      >
        <InputGroup
          id="search-input"
          leftIcon="filter"
          placeholder="Element contains ..."
          name="name"
          onChange={textInputChange(props.dispatch, 500)}
          defaultValue={props.state.name ?? undefined}
        />
      </FormGroup>

      <ControlGroup>
        <FormGroup
          label="Type"
          labelFor="type-input"
          contentClassName="margin-right-20"
        >
          <HTMLSelect
            id="type-input"
            name="type"
            onChange={textInputChange(props.dispatch)}
            options={['', ...GlobalStore.data.valueStore.elementTypes]}
            defaultValue={props.state.type ?? undefined}
          />
        </FormGroup>

        <FormGroup label="Min length" labelFor="length-input">
          <NumericInput
            id="length-input"
            name="minLength"
            leftIcon="less-than-or-equal-to"
            placeholder="Element bigger than ..."
            onValueChange={numericInputChange(props.dispatch, 500)}
            min={0}
            defaultValue={props.state.minLength ?? undefined}
          />
        </FormGroup>

        <FormGroup label="&nbsp;" style={{ marginLeft: 'auto' }}>
          <Button
            icon="reset"
            onClick={() => props.dispatch({ action: 'reset' })}
          >
            Reset
          </Button>
        </FormGroup>
      </ControlGroup>
    </Box>
  );
}

function Table(props: FormParams<GraphqlVariables>) {
  const { loading, error, data } = useQuery<GraphqlData, GraphqlVariables>(
    graphqlQuery,
    {
      variables: {
        name: props.state.name,
        type: props.state.type,
        minLength: props.state.minLength,
        page: props.state.page,
        limit: props.state.limit,
      },
    },
  );

  if (loading) return <Loading />;
  if (error) return <Error message={error.message} />;

  const elements = data?.elements?.edges;

  if (!data || !elements || !elements.length) {
    return <NoDataToDisplay />;
  }

  return (
    <>
      <Title>Elements ({data.elements.pageInfo.count.toLocaleString()})</Title>

      <HTMLTable className="width-100" interactive condensed striped>
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Description</th>
            <th>Type</th>
            <th>Length</th>
          </tr>
        </thead>
        <tbody>
          {elements.map((element) => (
            <tr key={element.id}>
              <td>{element.id}</td>
              <td>
                <Link to={`/elements/${element.id}`}>{element.name}</Link>
              </td>
              <td>{element.description}</td>
              <td align="center">{element.type}</td>
              <td align="right">{element.length.toLocaleString()}b</td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>

      <Pagination
        {...data?.elements.pageInfo}
        callback={pageChange(props.dispatch)}
      />
    </>
  );
}
