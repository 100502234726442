import { gql } from '@apollo/client';
import { File } from '../../graphql';

export interface GraphqlData {
  files: {
    packages: File[];
  };
}

export const graphqlQuery = gql`
  query {
    files {
      packages {
        name
        size
        url
      }
    }
  }
`;
