import { gql } from '@apollo/client';
import * as Graphql from '../../../graphql';

export type Entity = Pick<
  Graphql.Entity,
  | 'id'
  | 'cloudId'
  | 'name'
  | 'repeatNames'
  | 'type'
  | 'length'
  | 'orientation'
  | 'sequence'
  | 'sequenceEnd'
  | 'sequenceBegin'
>;

export interface GraphqlData {
  entities: {
    edges: Entity[];
    pageInfo: {
      count: number;
    };
  };
}

export interface GraphqlVariables {
  sequence: string;
  sequenceBeginGte: number;
  sequenceEndLte: number;
}

export const graphqlQuery = gql`
  query GetEntities(
    $sequence: String!
    $sequenceBeginGte: Int!
    $sequenceEndLte: Int!
  ) {
    entities(
      filter: {
        sequence: $sequence
        sequenceBeginGte: $sequenceBeginGte
        sequenceEndLte: $sequenceEndLte
      }
      pagination: { limit: 1000, page: 1 }
    ) {
      pageInfo {
        count
      }
      edges {
        id
        cloudId
        name
        repeatNames
        type
        length
        orientation
        sequence
        sequenceEnd
        sequenceBegin
      }
    }
  }
`;
