import { BaseModule } from '../baseModule';
import { EntityChart } from './chart';

export const key = 'entity';

export class EntityModule extends BaseModule {
  initialize() {
    this.chartPosition = 7;
    this.changeState('OK');
  }

  chartComponent() {
    return EntityChart;
  }
}
