import { PropsWithChildren } from 'react';
import './box.css';

interface BoxProps {
  className?: string;
}

export function Box(props: PropsWithChildren<BoxProps>) {
  return <div className={`box ${props.className}`}>{props.children}</div>;
}
