export const ENTITY_BROWSER_PADDING = 5000;

export const SERVER_URL =
  process.env.NODE_ENV === 'production'
    ? window.location.protocol + '//' + window.location.host
    : 'http://localhost:5000';

export const DEFAULT_TYPE = 'ERV';

export const GA_MEASUREMENT_ID = 'G-K27LFE645C';

export const LIMIT = 25;
