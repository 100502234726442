import { SettingValues } from '../../settings';
import { arrayToParam, paramToArray } from '../../utils/searchParams';
import { BaseModule } from '../baseModule';
import { SelectionPressuresChart } from './chart';
import { SelectionPressuresController } from './controller';
import { GraphqlData, graphqlQuery, GraphqlVariables } from './graphql';

export const key = 'selection';

export class SelectionPressuresModule extends BaseModule {
  initialize() {
    this.chartPosition = 5;
    this.controllerPosition = 6;

    this.eventBus.on('loadSearchParams', this.loadSearchParams.bind(this));
    this.eventBus.on('setSearchParams', this.setSearchParams.bind(this));
    this.eventBus.on('settingChanged', this.settingChanged.bind(this));
    this.eventBus.on('browserMounted', this.browserMounted.bind(this));
  }

  chartComponent() {
    return SelectionPressuresChart;
  }

  controllerComponent() {
    return SelectionPressuresController;
  }

  private browserMounted() {
    this.loadDataIfNecessary();
  }

  private async loadData(): Promise<void> {
    this.changeState('LOADING');

    const { sequence, sequenceBegin, sequenceEnd } =
      this.setting.get('position');

    return this.dataManager
      .load<GraphqlData, GraphqlVariables>(
        key,
        graphqlQuery,
        {
          sequence,
          positionGte: sequenceBegin,
          positionLte: sequenceEnd,
        },
        (data) => data.selectionPressures,
      )
      .then(() => this.changeState('OK'))
      .catch(() => this.changeState('ERROR'));
  }

  private loadSearchParams(params: URLSearchParams) {
    const param = params.get(key);

    this.setting.set('selection', paramToArray(param));
  }

  private setSearchParams(params: URLSearchParams) {
    const settingValue = this.setting.get('selection');

    params.set(key, arrayToParam(settingValue));
  }

  private settingChanged(keys: (keyof SettingValues)[]) {
    if (keys.includes('selection') || keys.includes('position')) {
      this.loadDataIfNecessary();
    }
  }

  private loadDataIfNecessary() {
    const values = this.setting.get('selection');

    if (values) {
      this.loadData();
    } else {
      this.changeState('OK');
    }
  }
}
