import { gql } from '@apollo/client';
import { Entity, Repeat } from '../../graphql';

export interface GraphqlData {
  entity: Pick<
    Entity,
    | 'id'
    | 'cloudId'
    | 'name'
    | 'repeatNames'
    | 'length'
    | 'repeatsLength'
    | 'gapsAbsolute'
    | 'gapsRelative'
    | 'type'
    | 'subtype'
    | 'schema'
    | 'sequence'
    | 'sequenceEnd'
    | 'sequenceBegin'
    | 'orientation'
  > & {
    repeats: Pick<
      Repeat,
      | 'id'
      | 'elementId'
      | 'name'
      | 'sequence'
      | 'sequenceEnd'
      | 'sequenceBegin'
      | 'orientation'
      | 'cigar'
      | 'error'
      | 'errorName'
      | 'repeatBegin'
      | 'repeatEnd'
      | 'repeatLeft'
      | 'entityBegin'
      | 'subtype'
    >[];
  };
}

export const graphqlQuery = gql`
  query GetEntity($id: Int!) {
    entity(id: $id) {
      id
      cloudId
      name
      repeatNames
      length
      repeatsLength
      gapsAbsolute
      gapsRelative
      type
      subtype
      schema
      sequence
      sequenceEnd
      sequenceBegin
      orientation
      repeats {
        id
        elementId
        name
        sequence
        sequenceEnd
        sequenceBegin
        orientation
        cigar
        error
        errorName
        repeatBegin
        repeatEnd
        repeatLeft
        entityBegin
        subtype
      }
    }
  }
`;
