import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';
import { useLocation } from 'react-router-dom';
import { GA_MEASUREMENT_ID } from '../config';

const test = process.env.NODE_ENV === 'development';

ReactGA.initialize(GA_MEASUREMENT_ID, {
  testMode: test,
});

export function useTracking() {
  const { pathname, search, state } = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: pathname + search });
  }, [pathname, search, state]);
}

export function trackEvent(params: UaEventOptions) {
  ReactGA.event(params);
}

export function externalLinkEvent(label: string) {
  ReactGA.event({
    label,
    category: 'Outbound',
    action: 'Click',
  });
}

export function trackCurrentLocation() {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search,
  });
}
