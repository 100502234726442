import { KonvaEventObject } from 'konva/lib/Node';
import { useContext } from 'react';
import { Group, Line, Rect, Text } from 'react-konva';
import { getCSS } from '../../../utils/css';
import { BrowserContext } from '../../browser';
import { ChartContext } from '../../chart';
import { Setting } from '../../settings';
import { useModuleStateReloader } from '../../utils/hooks';
import { convertSequencePosition, textWidth } from '../../utils/utils';
import { Repeat } from '../repeats/graphql';
import { RepeatsModule } from '../repeats/module';
import { EntitiesModule } from './module';
import './style.css';

const HEIGHT = 12;
const NAME_PADDING = 3;
const NAME_FONT_SIZE = 12;
const LEVEL_HEIGHT = HEIGHT + NAME_PADDING + NAME_FONT_SIZE + NAME_PADDING + 10;
const MARGIN = 10;

type EntityLevelType = Record<string, number>;
type PrevRepeatsType = Record<string, { x2: number; type: string }>;

export function EntitiesChart() {
  useModuleStateReloader(EntitiesModule, RepeatsModule);

  const { dataManager, setting } = useContext(BrowserContext);
  const { sequenceScale } = useContext(ChartContext);

  if (!isActive(setting)) {
    return null;
  }

  const entities = dataManager.get('entities');
  const repeats = dataManager.get('repeats');

  if (!entities || !repeats) {
    return null;
  }

  const entityLevel: EntityLevelType = {};
  const prevRepeats: PrevRepeatsType = {};
  const levelStack: number[] = [];
  const result: JSX.Element[] = [];
  const hoverRectsResult: JSX.Element[] = [];

  entities.forEach((entity) => {
    if (!isTypeActive(setting, entity.type)) {
      return;
    }

    const name = `#${entity.id}: ${entity.repeatNames?.join(', ')}`;
    const width = (entity.sequenceEnd - entity.sequenceBegin) * sequenceScale;
    const x = convertSequencePosition(
      entity.sequenceBegin,
      sequenceScale,
      setting.get('position'),
    );
    const x2 = x + Math.max(width, textWidth(name, NAME_FONT_SIZE)) + MARGIN;

    let level: number;
    for (level = 0; level < levelStack.length; level++) {
      const levelDetail = levelStack[level];

      if (levelDetail < x) {
        break;
      }
    }

    entityLevel[entity.id] = level;
    levelStack[level] = x2;

    const nameTextX = x < 0 ? 0 : x;
    const nameTextY = level * LEVEL_HEIGHT + HEIGHT + NAME_PADDING;

    result.push(
      <Text
        x={nameTextX}
        y={nameTextY}
        text={name}
        fontSize={NAME_FONT_SIZE}
        fill={getCSS('--text-color')}
      />,
    );

    const hoverRectX = nameTextX;
    const hoverRectY = level * LEVEL_HEIGHT;
    const hoverRectWidth = x2 - x;
    const hoverRectHeight = HEIGHT * 2 + NAME_PADDING;

    hoverRectsResult.push(
      <Rect
        x={hoverRectX}
        y={hoverRectY}
        width={hoverRectWidth}
        height={hoverRectHeight}
        onMouseEnter={setCursorToPointer}
        onMouseLeave={setCursorToDefault}
        onClick={() => window.open(`/entities/${entity.id}`, '_blank')?.focus()}
      />,
    );
  });

  repeats.forEach((repeat) => {
    const level = entityLevel[repeat.entityId];

    // Repeat doesn't have any entity or his type is disabled
    // Be careful, level could be 0 (valid value)
    if (level === undefined) {
      return;
    }

    const width = (repeat.sequenceEnd - repeat.sequenceBegin) * sequenceScale;
    const x = convertSequencePosition(
      repeat.sequenceBegin,
      sequenceScale,
      setting.get('position'),
    );
    const y = level * LEVEL_HEIGHT;

    result.push(
      <Rect
        x={x}
        y={y}
        width={width}
        height={HEIGHT}
        fill={repeatColor(repeat)}
      />,
    );

    const prevRepeat = prevRepeats[repeat.entityId];

    if (prevRepeat) {
      const distance = x - prevRepeat.x2;
      const middleX = prevRepeat.x2 + distance / 2;

      let stroke;
      if (prevRepeat.type === repeat.type) {
        stroke = repeatColor(repeat);
      } else {
        stroke = getCSS('--entity-chart-entity-line-color');
      }

      result.push(
        <Line
          stroke={stroke}
          points={[
            prevRepeat.x2,
            y + HEIGHT / 2,
            middleX,
            y,
            x,
            y + HEIGHT / 2,
          ]}
          strokeWidth={1}
          dash={[3, 2]}
        />,
      );
    }

    prevRepeats[repeat.entityId] = { x2: x + width, type: repeat.type };
  });

  return (
    <Group name="componentGroup">
      {result}
      {hoverRectsResult}
    </Group>
  );
}

function repeatColor(repeat: Repeat) {
  if (repeat.type === 'ERV' && repeat.subtype) {
    return getCSS(`--element-${repeat.subtype.toLowerCase()}`);
  } else {
    return getCSS('--element-nonerv');
  }
}

function isActive(setting: Setting) {
  return !!setting.get('entities')?.length;
}

function isTypeActive(setting: Setting, type: string) {
  return setting.get('entities')?.includes(type.toLowerCase());
}

function setCursorToPointer(event: KonvaEventObject<MouseEvent>) {
  const div = event.target.getStage()?.container();

  if (div) {
    div.style.cursor = 'pointer';
  }
}

function setCursorToDefault(event: KonvaEventObject<MouseEvent>) {
  const div = event.target.getStage()?.container();

  if (div) {
    div.style.cursor = 'default';
  }
}
