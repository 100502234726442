import { SettingValues } from '../../settings';
import { BaseModule } from '../baseModule';
import { GraphqlData, graphqlQuery, GraphqlVariables } from './graphql';

export const key = 'sequenceSize';

export class SequenceSizesModule extends BaseModule {
  initialize(): void {
    this.eventBus.on('settingChanged', this.settingChanged.bind(this));
    this.eventBus.on('browserMounted', this.browserMounted.bind(this));
  }

  private browserMounted() {
    this.loadData();
  }

  private async loadData(): Promise<void> {
    const { sequence } = this.setting.get('position');

    this.changeState('LOADING');

    return this.dataManager
      .load<GraphqlData, GraphqlVariables>(
        'sequenceSize',
        graphqlQuery,
        { sequence },
        (data) => data.sequenceSize,
      )
      .then(() => {
        this.changeState('OK');
      })
      .catch(() => {
        this.changeState('ERROR');
      });
  }

  private settingChanged(keys: (keyof SettingValues)[]) {
    if (keys.includes('position')) {
      this.loadData();
    }
  }
}
