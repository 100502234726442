import { Icon } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { PropsWithChildren } from 'react';
import './tableDetails.css';

export type TableDetailsData = Array<{
  label: string;
  value: string | number | JSX.Element | null | undefined;
  tooltip?: string;
}>;

interface TableDetailsProps {
  data: TableDetailsData;
}

export function TableDetails(props: PropsWithChildren<TableDetailsProps>) {
  return (
    <table className="table-details">
      <tbody>
        {props.data.map((item) => (
          <tr key={item.label}>
            <th>{item.label}</th>
            <td>
              {item.value}

              {item.tooltip && (
                <Tooltip2 content={item.tooltip} minimal>
                  <Icon
                    icon="info-sign"
                    intent="primary"
                    style={{ verticalAlign: 'middle', marginLeft: 5 }}
                  />
                </Tooltip2>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
