import { gql } from '@apollo/client';
import { SequenceSize, Element } from '../../graphql';

export interface GraphqlElement {
  sequencesSizes: SequenceSize[];
  element: Pick<
    Element,
    | 'id'
    | 'cloudId'
    | 'name'
    | 'description'
    | 'type'
    | 'subtype'
    | 'length'
    | 'keywords'
    | 'comment'
    | 'accessionNumbers'
    | 'references'
    | 'bestEntities'
    | 'repeatsDistribution'
  >;
}
export const graphqlQuery = gql`
  query GetElement($id: Int!, $bestEntitiesCount: Int) {
    sequencesSizes {
      sequence
      size
    }
    element(id: $id) {
      id
      cloudId
      name
      description
      type
      subtype
      length
      keywords
      comment
      accessionNumbers
      references {
        authors
        title
        location
      }
      bestEntities(limit: $bestEntitiesCount) {
        id
        name
        length
        repeats {
          repeatBegin
          repeatEnd
          repeatLeft
          entityBegin
          subtype
          orientation
          name
        }
      }
      repeatsDistribution {
        sequence
        count
      }
    }
  }
`;
