import { DataManager } from '../dataManager';
import { EventBus } from '../events';
import { Setting } from '../settings';

export type Component = () => JSX.Element | null;

type State = 'INITIALIZED' | 'OK' | 'LOADING' | 'ERROR';

export abstract class BaseModule {
  public state: State = 'INITIALIZED';
  public chartPosition = 0;
  public controllerPosition = 0;

  constructor(
    protected readonly eventBus: EventBus,
    protected readonly setting: Setting,
    protected readonly dataManager: DataManager,
  ) {
    this.eventBus = eventBus;

    this.initialize();
  }

  initialize() {
    return;
  }

  chartComponent(): Component | undefined {
    return;
  }

  controllerComponent(): Component | undefined {
    return;
  }

  protected changeState(newState: State) {
    this.state = newState;
    this.eventBus.emit('moduleStateChanged', this.constructor.name);
  }
}
