import { H3 } from '@blueprintjs/core';
import {
  Chart,
  BarController,
  LineController,
  BarElement,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  TimeScale,
  Tooltip,
  Legend,
  Filler,
  DoughnutController,
  ArcElement,
} from 'chart.js';
import { useRef, useContext, useEffect } from 'react';
import { ThemeContext } from '../theme';

if (Chart.defaults.animation) {
  Chart.defaults.animation.duration = 1;
}

Chart.register(
  BarController,
  LineController,
  DoughnutController,
  BarElement,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  TimeScale,
  Tooltip,
  Legend,
  ArcElement,
  Filler,
);

export function renderChart(params: {
  title?: string;
  draw: (context: CanvasRenderingContext2D) => Chart;
}) {
  const { draw, title } = params;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const themeContext = useContext(ThemeContext);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    const context = canvasRef.current.getContext('2d');

    if (!context) {
      return;
    }

    const chart = draw(context);

    return () => {
      chart.destroy();
    };
  }, [themeContext.theme]);

  return (
    <div>
      {title && <H3 className="text-align-center">{title}</H3>}
      <canvas ref={canvasRef} />
    </div>
  );
}
