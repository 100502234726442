import { gql } from '@apollo/client';
import { Element, Entity, Repeat } from '../../graphql';

export type SearchItem =
  | {
      type: 'Element';
      element: Pick<Element, 'id' | 'name' | 'cloudId' | 'type'>;
    }
  | {
      type: 'Entity';
      entity: Pick<
        Entity,
        'id' | 'name' | 'cloudId' | 'type' | 'length' | 'repeatNames'
      > & {
        repeats: Pick<
          Repeat,
          | 'repeatBegin'
          | 'repeatEnd'
          | 'repeatLeft'
          | 'entityBegin'
          | 'subtype'
          | 'orientation'
          | 'name'
        >[];
      };
    };

export interface GraphqlData {
  search: SearchItem[];
}

export const graphqlQuery = gql`
  query Search($query: String!, $limit: Int!) {
    search(query: $query, limit: $limit) {
      type
      ... on ElementSearchItem {
        element {
          id
          name
          cloudId
          type
        }
      }
      ... on EntitySearchItem {
        entity {
          id
          name
          cloudId
          type
          length
          repeatNames
          repeats {
            repeatBegin
            repeatEnd
            repeatLeft
            entityBegin
            subtype
            orientation
            name
          }
        }
      }
    }
  }
`;
