import { AboutPage } from './pages/aboutPage';
import { BrowserPage } from './pages/browserPage';
import { DownloadsPage } from './pages/downloads/downloadsPage';
import { ElementPage } from './pages/element/elementPage';
import { ElementsPage } from './pages/elements/elementsPage';
import { EntitiesPage } from './pages/entities/entitiesPage';
import { EntityPage } from './pages/entity/entityPage';
import { HomePage } from './pages/homePage';
import { StatisticsPage } from './pages/statistics/statisticsPage';
import { TermsOfUsePage } from './pages/termsOfUsePage';

interface TopMenu {
  path: string;
  label: string;
}

interface Route {
  path: string;
  end?: boolean;
  component: () => JSX.Element;
}

export const topMenu: TopMenu[] = [
  { path: '/statistics', label: 'Stats' },
  { path: '/browser', label: 'Browser' },
  { path: '/elements', label: 'Elements' },
  { path: '/entities', label: 'Entities' },
  { path: '/downloads', label: 'Downloads' },
  { path: '/about', label: 'About & History' },
];

export const routes: Route[] = [
  {
    path: '/',
    end: true,
    component: HomePage,
  },
  {
    path: '/statistics',
    component: StatisticsPage,
  },
  {
    path: '/elements',
    end: true,
    component: ElementsPage,
  },
  {
    path: '/elements/:id',
    component: ElementPage,
  },
  {
    path: '/entities',
    end: true,
    component: EntitiesPage,
  },
  {
    path: '/entities/:id',
    component: EntityPage,
  },
  {
    path: '/browser',
    component: BrowserPage,
  },
  {
    path: '/about',
    component: AboutPage,
  },
  {
    path: '/terms-of-use',
    component: TermsOfUsePage,
  },
  {
    path: '/downloads',
    component: DownloadsPage,
  },
];
