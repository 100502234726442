import { ApolloClient, DocumentNode } from '@apollo/client';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DataManagerValues {}

type Keys = keyof DataManagerValues;

export class DataManager {
  private readonly loaded: Record<string, string> = {};

  constructor(
    private readonly client: ApolloClient<object>,
    private readonly data: DataManagerValues = {},
  ) {}

  get<T extends Keys>(key: T): DataManagerValues[T] {
    return this.data[key];
  }

  set<T extends Keys>(key: T, value: DataManagerValues[T]) {
    this.data[key] = value;
  }

  async load<GraphqlData, GraphqlVariables, T extends Keys = Keys>(
    key: T,
    query: DocumentNode,
    variables: GraphqlVariables,
    transform: (data: GraphqlData) => DataManagerValues[T],
  ) {
    const cacheKey = `${key}-${JSON.stringify(variables)}`;

    if (this.loaded[key] === cacheKey) {
      return;
    } else {
      const { data, error } = await this.client.query<
        GraphqlData,
        GraphqlVariables
      >({
        query,
        variables,
      });

      if (error) {
        throw error;
      }

      this.data[key] = transform(data);
      this.loaded[key] = cacheKey;
    }
  }
}
