import { Classes } from '@blueprintjs/core';
import { ExternalLink } from '../components/links';
import { Title } from '../components/title';

export function TermsOfUsePage() {
  return (
    <>
      <Title>Terms of use</Title>
      <div className={Classes.TEXT_LARGE}>
        <p>
          This database is a portal which provide information and analysis of
          retroviral elements. The database is maintained at the Institute of
          Molecular Genetics, Academy of Sciences of the Czech Republic.
        </p>

        <p>
          All content on the website is provided without warranty and the owner
          is not responsible for any damages caused by the use of the site,
          errors, outages or any of the linked sites.
        </p>

        <p>
          The website collects only the necessary technical data that are not
          further processed. Data is not used for marketing purposes. The
          website uses Google Analytics to analyze pages. Specifically, to
          monitoring traffic.
        </p>

        <p>
          Content of the page is distributed under{' '}
          <ExternalLink href="https://creativecommons.org/licenses/by/4.0/">
            CC BY 4.0 licence
          </ExternalLink>
        </p>
      </div>
    </>
  );
}
