import { Button, Checkbox, Classes, H5, Switch } from '@blueprintjs/core';
import { pull, uniq } from 'lodash';
import { ChangeEvent, useContext } from 'react';
import { BrowserContext } from '../../browser';
import { Controls } from '../../components/controls';
import { useModuleStateReloader } from '../../utils/hooks';
import { SelectionPressuresModule } from './module';
import './style.css';

export function SelectionPressuresController() {
  const [timestamp] = useModuleStateReloader(SelectionPressuresModule);
  const { setting, eventBus, dataManager } = useContext(BrowserContext);

  const selectionData = dataManager.get('selection');

  if (!selectionData) {
    return (
      <Controls
        header={
          <Switch
            label="Selection Pressures"
            onChange={() => {
              setting.set('selection', []);
              eventBus.emit('settingChanged', ['selection']);
            }}
            defaultChecked={false}
          />
        }
      />
    );
  }

  if (!selectionData.length) {
    return <Controls header="No selection pressures data in this area" />;
  }

  const defaultChecked = (type: string) => {
    return setting.get('selection')?.includes(type.toLowerCase());
  };

  const uncheckAll = () => {
    setting.set('selection', []);
    eventBus.emit('settingChanged', ['selection']);
  };

  const checkAll = () => {
    setting.set(
      'selection',
      selectionData.map(({ meta }) => meta.name.toLowerCase()),
    );
    eventBus.emit('settingChanged', ['selection']);
  };

  const oneChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    const settingValue = setting.get('selection') || [];

    if (checked) {
      settingValue.push(value);
    } else {
      pull(settingValue, value);
    }

    setting.set('selection', settingValue);
    eventBus.emit('settingChanged', ['selection']);
  };

  const header = (
    <div className="flex-space-between">
      <span>Selection Pressures</span>

      <span>
        <Button icon="collapse-all" onClick={uncheckAll} minimal>
          Uncheck all
        </Button>

        <Button icon="expand-all" onClick={checkAll} minimal>
          Check all
        </Button>
      </span>
    </div>
  );

  const categories = uniq(selectionData.map((s) => s.meta.category)).sort();

  return (
    <Controls header={header} key={timestamp}>
      {categories.map((category) => (
        <div key={category}>
          <H5>{category}</H5>

          {selectionData
            .filter((sel) => sel.meta.category === category)
            .map(({ meta }) => (
              <Checkbox
                key={meta.name}
                labelElement={
                  <>
                    {meta.longName}{' '}
                    <span className={Classes.TEXT_MUTED}>{meta.type}</span>
                  </>
                }
                value={meta.name.toLowerCase()}
                defaultChecked={defaultChecked(meta.name)}
                onChange={oneChange}
                inline
              />
            ))}
        </div>
      ))}
    </Controls>
  );
}
