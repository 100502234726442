import { File } from '../../graphql';

export function getPackageName(file: File) {
  const match = file.name.match(/^package-(\w+)-(\w+)\.([\w\.]+)$/i);

  if (!match) {
    return file.name;
  }

  return `Package for ${match[2].toUpperCase()} (${match[3]})`;
}
