import { trackCurrentLocation } from '../app/tracking';
import { EventBus } from './events';
import { Position } from './interfaces';

export interface SettingValues {
  position: Position;
}

type Keys = keyof SettingValues;

export class Setting {
  constructor(
    private readonly eventBus: EventBus,
    private readonly values: SettingValues,
  ) {
    this.checkOrAlterPosition();

    eventBus.on('settingChanged', this.setSearchParams.bind(this));
  }

  get<T extends Keys>(key: T): SettingValues[T] {
    return this.values[key];
  }

  set<T extends Keys>(key: T, value: SettingValues[T]): void {
    this.values[key] = value;

    if (key === 'position') {
      this.checkOrAlterPosition();
    }
  }

  loadSearchParams() {
    const url = new URL(window.location.toString());

    if (url.searchParams.get('c') !== '1') {
      return;
    }

    const position = url.searchParams.get('position');

    if (position) {
      const parsedPosition = paramToPosition(position);

      if (parsedPosition) {
        this.set('position', parsedPosition);
      }
    }

    this.eventBus.emit('loadSearchParams', url.searchParams);
  }

  setSearchParams() {
    const url = new URL(window.location.toString());

    url.searchParams.set('c', '1');
    url.searchParams.set('position', positionToParams(this.get('position')));

    this.eventBus.emit('setSearchParams', url.searchParams);

    window.history.pushState({}, '', url.toString());

    trackCurrentLocation();
  }

  private checkOrAlterPosition() {
    if (this.values.position.sequenceBegin > this.values.position.sequenceEnd) {
      this.values.position.sequenceEnd = this.values.position.sequenceBegin;
    }
  }
}

function paramToPosition(value: string): Position | undefined {
  const match = value.match(/^(\w+)\|(\d+)\|(\d+)$/);

  if (!match) {
    return;
  }

  return {
    sequence: match[1],
    sequenceBegin: parseInt(match[2]),
    sequenceEnd: parseInt(match[3]),
  };
}

function positionToParams(position: Position) {
  return `${position.sequence}|${position.sequenceBegin}|${position.sequenceEnd}`;
}
