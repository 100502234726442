import { Classes } from '@blueprintjs/core';
import { compact, sortBy } from 'lodash';
import { useContext } from 'react';
import { BrowserContext } from './browser';

export function Controller() {
  const { modules } = useContext(BrowserContext);

  const controllerComponents = compact(
    sortBy(modules, (mod) => mod.controllerPosition).map((mod) =>
      mod.controllerComponent(),
    ),
  );

  return (
    <div className={`${Classes.FOCUS_DISABLED} browser-controller`}>
      {controllerComponents.map((Component) => (
        <Component key={Component.name} />
      ))}
    </div>
  );
}
