import { Switch } from '@blueprintjs/core';
import { useContext, ChangeEvent } from 'react';
import { BrowserContext } from '../../browser';
import { Controls } from '../../components/controls';
import { cytobandKey } from './module';

export function CytobandController() {
  const { setting, eventBus } = useContext(BrowserContext);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setting.set(cytobandKey, event.target.checked);
    eventBus.emit('settingChanged', [cytobandKey]);
  };

  const header = (
    <Switch
      label="Cytoband"
      onChange={onChange}
      defaultChecked={setting.get('cytoband')}
    />
  );

  return <Controls header={header} />;
}
