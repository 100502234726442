import { Button, HTMLSelect } from '@blueprintjs/core';
import { BaseSyntheticEvent, useContext, useRef } from 'react';
import { GlobalStore } from '../globalStore';
import { BrowserContext } from './browser';
import { Position } from './interfaces';

export function PositionSelection() {
  const { setting, eventBus } = useContext(BrowserContext);

  const sequenceRef = useRef<HTMLSelectElement>(null);
  const sequenceBeginRef = useRef<HTMLInputElement>(null);
  const sequenceEndRef = useRef<HTMLInputElement>(null);

  const { sequence, sequenceBegin, sequenceEnd } = setting.get('position');

  const submit = (event: BaseSyntheticEvent) => {
    if (
      sequenceRef.current &&
      sequenceBeginRef.current &&
      sequenceEndRef.current
    ) {
      const newPosition: Position = {
        sequence: sequenceRef.current.value,
        sequenceBegin: extractNumber(sequenceBeginRef.current.value),
        sequenceEnd: extractNumber(sequenceEndRef.current.value),
      };

      setting.set('position', newPosition);
      eventBus.emit('settingChanged', ['position']);

      event.preventDefault();
    }
  };

  return (
    <div className="browser-position-selection">
      <form onSubmit={submit}>
        <HTMLSelect
          elementRef={sequenceRef}
          options={GlobalStore.data.sequencesSizes.map((s) => s.sequence)}
          defaultValue={sequence}
          onChange={submit}
        />
        <input
          ref={sequenceBeginRef}
          type="text"
          key={`sequenceBegin-${sequenceBegin}`}
          defaultValue={sequenceBegin}
          // onFocus={unformatInput}
          // onKeyUp={formatInput}
          // onBlur={formatInput}
        />
        -
        <input
          ref={sequenceEndRef}
          type="text"
          key={`sequenceEnd-${sequenceEnd}`}
          defaultValue={sequenceEnd}
          // onFocus={unformatInput}
          // onKeyUp={formatInput}
          // onBlur={formatInput}
        />
        <Button icon="updated" onClick={submit} type="submit" minimal />
      </form>
    </div>
  );
}

// function unformatInput(event: FocusEvent<HTMLInputElement>) {
//   event.currentTarget.value = extractNumber(
//     event.currentTarget.value,
//   ).toString();
// }

// function formatInput(event: FocusEvent<HTMLInputElement>) {
//   event.currentTarget.value = formatNumber(event.currentTarget.value);
// }

function extractNumber(value: string) {
  return parseInt(value.replace(/\D/g, ''));
}

// function formatNumber(originalValue: string | number) {
//   const value = extractNumber(originalValue.toString());

//   return value.toLocaleString();
// }
