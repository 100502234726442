import { Entity, SequenceSize, ValueStore } from './graphql';

export interface Data {
  sequencesSizes: SequenceSize[];
  valueStore: ValueStore;
  representativeEntity: Entity;
  sequences: string[];
}

const data: Data = {} as any;

export function setGlobalData(newData: Data) {
  Object.assign(data, newData);
}

export const GlobalStore = {
  data,
};
