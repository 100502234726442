import { gql, useQuery } from '@apollo/client';
import { Classes, Drawer, Icon } from '@blueprintjs/core';
import { useContext, useState } from 'react';
import { Error } from '../../components/error';
import { AccessionNumbersLinks } from '../../components/links';
import { Loading } from '../../components/loading';
import { NoDataToDisplay } from '../../components/noDataToDisplay';
import { Element } from '../../graphql';
import { themeClass, ThemeContext } from '../../theme';

interface ElementProps {
  id: number;
  name: string;
}

interface GraphqlData {
  element: Pick<
    Element,
    | 'id'
    | 'cloudId'
    | 'name'
    | 'description'
    | 'type'
    | 'subtype'
    | 'length'
    | 'keywords'
    | 'comment'
    | 'accessionNumbers'
    | 'references'
  >;
}

const graphqlQuery = gql`
  query GetElement($id: Int!) {
    element(id: $id) {
      id
      cloudId
      name
      description
      type
      subtype
      length
      keywords
      comment
      accessionNumbers
      references {
        authors
        title
        location
      }
    }
  }
`;

export function ElementDetail(props: ElementProps) {
  const { theme } = useContext(ThemeContext);
  const [opened, setOpened] = useState<boolean>(false);

  const { loading, error, data } = useQuery<GraphqlData>(graphqlQuery, {
    variables: { id: props.id },
  });

  if (loading) return <Loading />;
  if (error) return <Error message={error.message} />;

  if (!data?.element) {
    return <NoDataToDisplay />;
  }

  const { element } = data;
  let drawer: JSX.Element | null = null;

  if (opened) {
    drawer = (
      <Drawer
        className={themeClass(theme)}
        onClose={() => setOpened(false)}
        title={`Element: ${element.name}`}
        isOpen
      >
        <div className={Classes.DRAWER_BODY}>
          <div className={Classes.DIALOG_BODY}>
            <dl className="dl1">
              <dt>Cloud id</dt>
              <dd>
                <div
                  className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED} ${Classes.MONOSPACE_TEXT}`}
                >
                  <Icon icon="social-media" className="margin-right-5" />
                  {element.cloudId}
                </div>
              </dd>
              <dt>Type</dt>
              <dd>{element.type}</dd>
              <dt>Subtype</dt>
              <dd>{element.subtype}</dd>
              <dt>Length</dt>
              <dd>{element.length}</dd>
              <dt>Keywords</dt>
              <dd>{element.keywords?.join(', ') ?? '-'}</dd>
              <dt>Accession numbers</dt>
              <dd>
                <AccessionNumbersLinks numbers={element.accessionNumbers} />
              </dd>
              <dt>Description</dt>
              <dd>{element.description}</dd>
            </dl>
          </div>
        </div>
        {/* <div className={Classes.DRAWER_FOOTER}>Close the dialog by cli</div> */}
      </Drawer>
    );
  }

  return (
    <>
      <a onClick={() => setOpened(true)}>{props.name}</a>
      {drawer}
    </>
  );
}
