import { BaseModule } from '../baseModule';
import { DownloadController } from './controller';

export class DownloadModule extends BaseModule {
  initialize() {
    this.controllerPosition = 2;
    this.changeState('OK');
  }

  controllerComponent() {
    return DownloadController;
  }
}
