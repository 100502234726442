import './level.css';

interface LevelProps {
  items: Array<{
    label: string;
    value: string | number | JSX.Element | undefined | null;
  }>;
}

export function Level(props: LevelProps) {
  return (
    <div className="level level--boxed">
      {props.items.map((item) => (
        <div key={item.label} className="level__item">
          <p className="level__heading">{item.label}</p>
          <p className="level__content">{item.value}</p>
        </div>
      ))}
    </div>
  );
}
