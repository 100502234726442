import { useContext } from 'react';
import { Group, Line, Rect, Text } from 'react-konva';
import { getCSS } from '../../../utils/css';
import { humanSize } from '../../../utils/parsers';
import { BrowserContext } from '../../browser';
import { ChartContext } from '../../chart';
import { Position } from '../../interfaces';
import { useModuleStateReloader } from '../../utils/hooks';
import { convertSequencePosition } from '../../utils/utils';
import { Entity } from '../entities/graphql';
import { Repeat } from '../repeats/graphql';
import { RepeatsModule } from '../repeats/module';
import './style.css';

const SEQUENCE_HEIGHT = 40;
const CONSENSUS_HEIGHT = 20;
const CONNECTIONS_HEIGHT = 60;
const CONSENSUS_TRACK_LINE_HEIGHT = 5;
const CONSENSUS_TRACK_LINE_SPACING = 50;
const CONSENSUS_TRACK_TEXT_SPACING = 100;

export function EntityChart() {
  useModuleStateReloader(RepeatsModule);

  const { sequenceScale, width } = useContext(ChartContext);
  const { dataManager } = useContext(BrowserContext);

  const entity = dataManager.get('entity');
  const repeats = dataManager.get('repeats');

  if (!entity || !repeats) {
    return null;
  }

  const consensusLeftMargin = (width - entity.length * sequenceScale) / 2;
  const consensusWidth = entity.length * sequenceScale;

  return (
    <Group name="componentGroup">
      <Group name="sequenceGroup">
        <RepeatsTrack />
      </Group>
      <Group
        name="consensusGroup"
        x={consensusLeftMargin}
        y={SEQUENCE_HEIGHT + CONNECTIONS_HEIGHT}
      >
        <ConsensusTrack consensusWidth={consensusWidth} />
        <ConsensusRepeatsTrack consensusWidth={consensusWidth} />
      </Group>
      <ConnectionsTrack consensusLeftMargin={consensusLeftMargin} />
    </Group>
  );
}

function RepeatsTrack() {
  const { dataManager, setting } = useContext(BrowserContext);
  const { sequenceScale, width } = useContext(ChartContext);

  const repeats: JSX.Element[] = [];
  const entity = dataManager.get('entity')!;

  dataManager.get('repeats')!.forEach((repeat) => {
    const { x, y, width, height } = repeatDimension(
      repeat,
      setting.get('position'),
      sequenceScale,
      entity,
    );

    repeats.push(
      <Rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={repeatColor(repeat, entity)}
      />,
    );
  });

  return (
    <>
      {repeats}
      <Line
        points={[0, SEQUENCE_HEIGHT, width, SEQUENCE_HEIGHT]}
        strokeWidth={1}
        stroke={getCSS('--entity-chart-sequence-track-color')}
      />
    </>
  );
}

function ConsensusTrack(props: { consensusWidth: number }) {
  const { consensusWidth } = props;
  const { sequenceScale } = useContext(ChartContext);

  const horizontalLine = (x: number) => {
    return (
      <Line
        points={[x, 0, x, CONSENSUS_TRACK_LINE_HEIGHT]}
        stroke={getCSS('--entity-chart-consensus-lines-color')}
        strokeWidth={1}
      />
    );
  };

  const lines = [
    <Line
      key={0}
      points={[0, 0, consensusWidth, 0]}
      stroke={getCSS('--entity-chart-consensus-lines-color')}
      strokeWidth={1}
    />,
  ];

  for (let x = 0; x <= consensusWidth; x += CONSENSUS_TRACK_LINE_SPACING) {
    horizontalLine(x);
  }

  // Final line
  horizontalLine(consensusWidth);

  const positions = [];

  // Positions
  for (let x = 0; x <= consensusWidth; x += CONSENSUS_TRACK_TEXT_SPACING) {
    const position = x / sequenceScale;
    positions.push(
      <Text
        x={x}
        text={humanSize(position, { fixed: 1 })}
        fill={getCSS('--entity-chart-consensus-lines-color')}
      />,
    );
  }

  return (
    <Group name="trackGroup" y={CONSENSUS_HEIGHT + 5}>
      {lines}
      <Group name="positionsGroup" y={CONSENSUS_TRACK_LINE_HEIGHT + 2}>
        {positions}
      </Group>
    </Group>
  );
}

function ConsensusRepeatsTrack(props: { consensusWidth: number }) {
  const { consensusWidth } = props;
  const { dataManager } = useContext(BrowserContext);
  const { sequenceScale } = useContext(ChartContext);

  const repeats: JSX.Element[] = [];
  const entity = dataManager.get('entity')!;

  dataManager.get('repeats')!.forEach((repeat) => {
    if (repeat.entityId !== entity.id) {
      return null;
    }

    const { x, width, height } = consensusRepeatDimension(
      sequenceScale,
      repeat,
    );

    repeats.push(
      <Rect
        x={x}
        width={width}
        height={height}
        fill={repeatColor(repeat, entity)}
      />,
    );
  });

  return (
    <>
      <Rect
        width={consensusWidth}
        height={CONSENSUS_HEIGHT}
        stroke={getCSS('--entity-chart-consensus-lines-color')}
        strokeWidth={1}
      />
      {repeats}
    </>
  );
}

function ConnectionsTrack(props: { consensusLeftMargin: number }) {
  const { consensusLeftMargin } = props;
  const { dataManager, setting } = useContext(BrowserContext);
  const { sequenceScale } = useContext(ChartContext);

  const entity = dataManager.get('entity')!;

  const renderLine = (x1: number, y1: number, x2: number, y2: number) => {
    return (
      <Line
        points={[x1, y1, x2, y2]}
        stroke={getCSS('--entity-chart-consensus-lines-color')}
        strokeWidth={0.5}
      />
    );
  };

  const lines: JSX.Element[] = [];

  dataManager.get('repeats')!.forEach((repeat) => {
    if (repeat.entityId !== entity.id) {
      return;
    }

    const topDimension = repeatDimension(
      repeat,
      setting.get('position'),
      sequenceScale,
      entity,
    );
    const bottomDimension = consensusRepeatDimension(sequenceScale, repeat);

    lines.push(
      renderLine(
        topDimension.x,
        topDimension.y + topDimension.height,
        bottomDimension.x + consensusLeftMargin,
        SEQUENCE_HEIGHT + CONNECTIONS_HEIGHT,
      ),
    );
    lines.push(
      renderLine(
        topDimension.x + topDimension.width,
        topDimension.y + topDimension.height,
        bottomDimension.x + bottomDimension.width + consensusLeftMargin,
        SEQUENCE_HEIGHT + CONNECTIONS_HEIGHT,
      ),
    );
  });

  return <>{lines}</>;
}

function repeatLengthInEntity(repeat: Repeat) {
  if (repeat.orientation === 'F') {
    return repeat.entityEnd - repeat.entityBegin;
  } else {
    return repeat.entityEnd - repeat.entityLeft;
  }
}

function consensusRepeatDimension(sequenceScale: number, repeat: Repeat) {
  const x = repeat.entityBegin * sequenceScale;
  const width = repeatLengthInEntity(repeat) * sequenceScale;
  const height = CONSENSUS_HEIGHT;

  return { x, width, height };
}

function repeatColor(repeat: Repeat, entity: Entity) {
  if (repeat.entityId === entity.id) {
    if (repeat.type === 'ERV' && repeat.subtype) {
      return getCSS(`--element-${repeat.subtype.toLowerCase()}`);
    } else {
      return getCSS('--element-nonerv');
    }
  } else {
    return getCSS('--element-gray');
  }
}

function repeatDimension(
  repeat: Repeat,
  position: Position,
  sequenceScale: number,
  entity: Entity,
) {
  const width = (repeat.sequenceEnd - repeat.sequenceBegin) * sequenceScale;
  const height = repeat.entityId === entity.id ? 30 : 20;
  const x = convertSequencePosition(
    repeat.sequenceBegin,
    sequenceScale,
    position,
  );
  const y = SEQUENCE_HEIGHT - height;

  return { x, y, width, height };
}
