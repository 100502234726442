export class SearchParams {
  url: URL;

  constructor() {
    this.url = new URL(window.location.toString());
  }

  getString(key: string): string | undefined;
  getString(key: string, otherwise: string): string;
  getString(key: string, otherwise?: string): string | undefined {
    return this.url.searchParams.get(key) || otherwise;
  }

  getInt(key: string): number | undefined;
  getInt(key: string, otherwise: number): number;
  getInt(key: string, otherwise?: number): number | undefined {
    const value = this.url.searchParams.get(key);

    if (value) {
      return parseInt(value);
    } else {
      return otherwise;
    }
  }
}
