import { useContext } from 'react';
import { Group, Text } from 'react-konva';
import { getCSS } from '../../../utils/css';
import { BrowserContext } from '../../browser';
import { useModuleStateReloader } from '../../utils/hooks';
import { SequenceSizesModule } from '../sequenceSizes/module';

export function TopInfoChart() {
  useModuleStateReloader(SequenceSizesModule);

  const { dataManager, setting } = useContext(BrowserContext);

  const position = setting.get('position');
  const sequenceSize = dataManager.get('sequenceSize');
  const entity = dataManager.get('entity');

  if (!sequenceSize) {
    return null;
  }

  return (
    <Group name="componentGroup">
      <Text
        text={`${document.title} - (${
          sequenceSize.sequence
        }: ${position.sequenceBegin.toLocaleString()} - ${position.sequenceEnd.toLocaleString()}) - ${
          window.location.hostname
        }`}
        fill={getCSS('--text-color')}
        fontFamily="monospace"
      />
      {entity && (
        <Text
          text={`[cloud_id] ${entity.cloudId}`}
          y={20}
          fill={getCSS('--text-color')}
          fontFamily="monospace"
        />
      )}
    </Group>
  );
}
