import { IconName, Intent, Tag } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';

interface RepeatErrorProps {
  repeat: {
    error?: number | null;
    errorName?: string | null;
  };
}

export function RepeatError(props: RepeatErrorProps) {
  const { error, errorName } = props.repeat;

  if (!error || !errorName) {
    return null;
  }

  const tag = (intent: Intent, icon: IconName) => {
    return (
      <Tooltip2
        content={`Calculated length is different by ${Math.ceil(
          error * 100
        )}% compared to the original`}
      >
        <Tag intent={intent} icon={icon} minimal>
          {errorName}
        </Tag>
      </Tooltip2>
    );
  };

  if (error >= 0.5) {
    return tag('danger', 'error');
  }

  if (error >= 0.3) {
    return tag('warning', 'warning-sign');
  }

  if (error >= 0.1) {
    return tag('primary', 'info-sign');
  }

  return null;
}
