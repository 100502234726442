import { H1 } from '@blueprintjs/core';
import { PropsWithChildren } from 'react';

export function Title(props: PropsWithChildren<unknown>) {
  if (Array.isArray(props.children)) {
    document.title = props.children.map((child) => child?.toString()).join(' ');
  } else if (props.children) {
    document.title = props.children.toString();
  } else {
    document.title = 'ERVd';
  }

  return <H1>{props.children}</H1>;
}
