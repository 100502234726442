import { gql } from '@apollo/client';
import { DataEdge, Element, ElementsFilter, Pagination } from '../../graphql';

export interface GraphqlData {
  elements: DataEdge<
    Pick<Element, 'id' | 'name' | 'description' | 'type' | 'subtype' | 'length'>
  >;
}

export type GraphqlVariables = ElementsFilter & Pagination;

export const graphqlQuery = gql`
  query GetElements(
    $name: String
    $type: String
    $minLength: Int
    $page: Int!
    $limit: Int!
  ) {
    elements(
      filter: { name: $name, type: $type, minLength: $minLength }
      pagination: { page: $page, limit: $limit }
    ) {
      pageInfo {
        count
        page
        limit
        offset
      }
      edges {
        id
        name
        description
        type
        subtype
        length
      }
    }
  }
`;
