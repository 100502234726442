import { Classes, H2, H3, H4 } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { Email } from '../components/email';
import { ExternalLink } from '../components/links';

export function AboutPage() {
  return (
    <>
      <AboutUs />

      <div className="margin-top-50">
        <People />
      </div>

      <div className="margin-top-50">
        <History />
      </div>
    </>
  );
}

function AboutUs() {
  return (
    <div className="flex-columns flex-columns--20">
      <div>
        <Introduction />
      </div>
      <div>
        <RegisteredAndBased />
      </div>
    </div>
  );
}

function Introduction() {
  return (
    <>
      <H2>ERVd</H2>

      <p>
        The endogenous retroviruses database (ERVd) is maintained at the{' '}
        <ExternalLink href="https://www.img.cas.cz/en">
          Institute of Molecular Genetics, Academy of Sciences of the Czech
          Republic
        </ExternalLink>
        .
      </p>

      <p>
        The ERVd provides complex information on and analysis of retroviral
        elements found in the human genome. It can be used for searches of
        individual ERV families, identification of ERV parts, graphical output
        of ERV structures, comparison of ERVs and identification of retrovirus
        integration sites.
      </p>

      <p>
        Website follow this <Link to="/terms-of-use">terms of use</Link>.
      </p>
    </>
  );
}

function RegisteredAndBased() {
  return (
    <>
      <H3>Registered on</H3>

      <ul>
        <li>
          <ExternalLink href="https://elixir-europe.org/services/list?combine=hervd">
            In elixir-europe services
          </ExternalLink>
        </li>
        <li>
          <ExternalLink href="https://bio.tools/hervd">
            Bio-tools (HERVd)
          </ExternalLink>
        </li>
        <li>
          <ExternalLink href="https://fairsharing.org/biodbcore-000193">
            Fairsharing
          </ExternalLink>
        </li>
      </ul>

      <H3>Based on</H3>

      <ul>
        <li>
          <ExternalLink href="http://www.repeatmasker.org">
            RepeatMasker
          </ExternalLink>
        </li>
        <li>
          <ExternalLink href="http://www.girinst.org/repbase">
            Repbase
          </ExternalLink>
        </li>
      </ul>
    </>
  );
}

function People() {
  return (
    <div className="flex-columns flex-columns--20">
      <div>
        <H3>Current curators</H3>

        <ul>
          <li>
            Ondřej Moravčík
            <ul>
              <li>
                <Email
                  name="Ondřej Moravčík"
                  email="ondrej.moravcik@img.cas.cz"
                />
              </li>
            </ul>
          </li>
          <li>
            Jan Pačes
            <ul>
              <li>
                <Email name="Jan Pačes" email="jan.paces@img.cas.cz" />
              </li>

              <li>
                <ExternalLink href="https://www.researcherid.com/rid/C-8851-2009">
                  ResearcherID
                </ExternalLink>
              </li>
            </ul>
          </li>
          <li>Dan Elleder</li>
        </ul>
      </div>

      <div>
        <H3>Collaborators </H3>

        <ul>
          <li>
            Jiří Reiniš{' '}
            <em className={Classes.TEXT_MUTED}>
              HERV transcritpional activity in immune cells
            </em>
          </li>
          <li>
            Ho Tuan Anh, Mariana Šatrová{' '}
            <em className={Classes.TEXT_MUTED}>
              Selection pressure on HERV loci
            </em>
          </li>
          <li>
            Jaroslav Kurfürst, Yeva Prysiazhniuk{' '}
            <em className={Classes.TEXT_MUTED}>
              endogenous retroviruses in chicken
            </em>
          </li>
          <li>Radek Zí­ka</li>
          <li>Adam Pavlí­ček</li>
          <li>Lucie Studená</li>
          <li>Mirka Famfulí­ková</li>
          <li>Vladimir V. Kapitonov</li>
          <li>Jerzy Jurka</li>
          <li>Václav Pačes</li>
        </ul>
      </div>
    </div>
  );
}

function History() {
  return (
    <>
      <H3>History</H3>

      <H4>Version v3 (2021)</H4>

      <ul>
        <li>RepeatMasker v4</li>
        <li>Using Dfam database</li>
        <li>More accurate result using ssearch</li>
        <li>Integrated genes from GENCODE and Ensembl</li>
        <li>Added cloud uniq identifier (cloudId)</li>
        <li>Browser feature to see data from whole/part chromosome</li>
        <li>New web ui</li>
        {/* <li>Written in Python and Typescript</li> */}
      </ul>

      <H4>Version v2 (2016)</H4>

      <ul>
        <li>RepeatMasker v3</li>
        <li>Written in Ruby and Python</li>
        <li>Using all types of elements</li>
        <li>Clustering entities using mcl program</li>
      </ul>

      <H4>Version v1 (2000)</H4>

      <ul>
        <li>Using Girinst DB</li>
        <li>Only for human species (hg17)</li>
        <li>RepeatMasker v2</li>
        <li>Written in Perl</li>
        <li>Only ERV elements</li>
      </ul>
    </>
  );
}
