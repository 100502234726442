import { gql } from '@apollo/client';
import * as Graphql from '../../../graphql';

export type Gene = Pick<
  Graphql.Gene,
  | 'id'
  | 'geneId'
  | 'parentId'
  | 'orientation'
  | 'sequence'
  | 'sequenceBegin'
  | 'sequenceEnd'
  | 'name'
  | 'type'
>;

export interface GraphqlData {
  genes: Gene[];
}

export interface GraphqlVariables {
  sequence: string;
  sequenceBeginGte: number;
  sequenceEndLte: number;
}

export const graphqlQuery = gql`
  query GetGenes(
    $sequence: String!
    $sequenceBeginGte: Int!
    $sequenceEndLte: Int!
  ) {
    genes(
      filter: {
        sequence: $sequence
        sequenceBeginGte: $sequenceBeginGte
        sequenceEndLte: $sequenceEndLte
      }
      pagination: { limit: 1000, page: 1 }
    ) {
      id
      geneId
      parentId
      orientation
      sequence
      sequenceBegin
      sequenceEnd
      name
      type
    }
  }
`;
