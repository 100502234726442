import { Button, Classes, Navbar } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { NavLink } from 'react-router-dom';
import elixirLogo from '../assets/elixir_logo_small.png';
import imgLogo from '../assets/img_logo_small.png';
import logo from '../assets/logo.svg';
import { OmniSearch } from '../components/omniSearch/omniSearch';
import { GlobalStore } from '../globalStore';
import { topMenu } from '../routes';
import { ThemeContext } from '../theme';
import { externalLinkEvent } from './tracking';

export function Header() {
  return (
    <Navbar className={Classes.FOCUS_DISABLED}>
      <div className="header">
        <Navbar.Group align="left">
          <Navbar.Heading>
            <NavLink to="/" className="header__logo" end>
              <img src={logo} alt="Logo" />
              {GlobalStore.data.valueStore.shortName}
            </NavLink>
          </Navbar.Heading>

          <Navbar.Divider />

          {topMenu.map((route) => (
            <NavLink
              key={route.path}
              className={({ isActive }) =>
                `${Classes.BUTTON} ${Classes.MINIMAL} ${
                  isActive && Classes.ACTIVE
                }`
              }
              to={route.path}
            >
              {route.label}
            </NavLink>
          ))}
        </Navbar.Group>

        <Navbar.Group align="right">
          <a
            className="margin-right-20 font-size-0"
            href="https://www.img.cas.cz/en"
            target="_blank"
            rel="noreferrer"
            onClick={(event) => {
              externalLinkEvent(event.currentTarget.href);
            }}
          >
            <img
              src={imgLogo}
              alt="Institute of Molecular Genetics of the Czech Academy of Sciences"
            />
          </a>
          <a
            className="font-size-0"
            href="https://www.elixir-czech.cz"
            target="_blank"
            rel="noreferrer"
            onClick={(event) => {
              externalLinkEvent(event.currentTarget.href);
            }}
          >
            <img src={elixirLogo} alt="ELIXIR CZ" />
          </a>
          <Navbar.Divider />

          <OmniSearch />

          <ThemeContext.Consumer>
            {(theme) => (
              <Tooltip2 content="Click to switch theme" position="bottom">
                <Button
                  icon={theme.theme === 'light' ? 'moon' : 'flash'}
                  onClick={theme.toggleTheme}
                  minimal
                ></Button>
              </Tooltip2>
            )}
          </ThemeContext.Consumer>
        </Navbar.Group>

        <div style={{ clear: 'both' }}></div>
      </div>
    </Navbar>
  );
}
