import { H3 } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import elixirLogo from '../assets/elixir_logo_middle.png';
import imgLogo from '../assets/img_logo_middle.png';
import { Browser } from '../browser/browser';
import { Email } from '../components/email';
import { ExternalLink } from '../components/links';
import { Title } from '../components/title';
import { GlobalStore } from '../globalStore';

export function HomePage() {
  const entity = GlobalStore.data.representativeEntity;

  return (
    <>
      <Title>{GlobalStore.data.valueStore.name}</Title>

      <div
        style={{ columnWidth: 300, columnGap: 40 }}
        dangerouslySetInnerHTML={{
          __html: GlobalStore.data.valueStore.introductionHtml ?? '',
        }}
      ></div>

      <div className="margin-top-50 margin-bottom-50">
        <Browser
          entity={entity}
          sequence={entity.sequence}
          sequenceBegin={entity.sequenceBegin}
          sequenceEnd={entity.sequenceEnd}
          minimal
        />
      </div>

      <H3>References</H3>

      <ul>
        <li>
          <span>
            Paces J, Pavlicek A, Paces V. HERVd: database of human endogenous
            retroviruses. Nucleic Acids Res. 2002 Jan 1;30(1):205-6.
          </span>
          <ExternalLink href="https://www.ncbi.nlm.nih.gov/pubmed/11752294?dopt=Abstract">
            Medline
          </ExternalLink>
          |
          <ExternalLink href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC99133/">
            Full text
          </ExternalLink>
        </li>
        <li>
          <span>
            Jan Paces, Adam Pavlicek, Radek Zika, Vladimir V. Kapitonov, Jerzy
            Jurka, and Vaclav Paces: HERVd: the Human Endogenous RetroViruses
            Database: update Nucl. Acids. Res. 2004 32: D50
          </span>
          <ExternalLink href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC308809/">
            Full text
          </ExternalLink>
        </li>
      </ul>

      <H3>Contacts</H3>

      <ul>
        <li>
          <Email name="Ondřej Moravčík" email="ondrej.moravcik@img.cas.cz" />
        </li>

        <li>
          <Email name="Jan Pačes" email="jan.paces@img.cas.cz" />
        </li>
      </ul>

      <H3>Important pages</H3>

      <ul>
        <li>
          <Link to="/terms-of-use">Terms of use</Link>
        </li>
        <li>
          <Link to="/about"> About</Link>
        </li>
      </ul>

      <p>&nbsp;</p>

      <p className="institutes">
        <a href="https://www.img.cas.cz" target="_blank" rel="noreferrer">
          <img
            src={imgLogo}
            alt="Institute of Molecular Genetics of the Czech Academy of Sciences"
          />
        </a>

        <a href="https://www.elixir-czech.cz" target="_blank" rel="noreferrer">
          <img src={elixirLogo} alt="ELIXIR CZ" />
        </a>
      </p>
    </>
  );
}
