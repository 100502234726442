import { Callout } from '@blueprintjs/core';

interface ErrorProps {
  message: JSX.Element | string;
}

export function Error(props: ErrorProps) {
  return (
    <Callout intent="danger" icon="error">
      {props.message}
    </Callout>
  );
}
