import { useQuery } from '@apollo/client';
import { BarChart, DataItem as BarDataItem } from '../../charts/bar';
import { PieChart } from '../../charts/pie';
import { elementsSchema } from '../../charts/schema';
import { Card } from '../../components/card';
import { EntityLink } from '../../components/entities';
import { Error } from '../../components/error';
import { Level } from '../../components/level';
import { Loading } from '../../components/loading';
import { NoDataToDisplay } from '../../components/noDataToDisplay';
import { Title } from '../../components/title';
import { GlobalStore } from '../../globalStore';
import {
  GraphqlData,
  graphqlQuery,
  GraphqlEntitiesData,
  graphqlEntitiesQuery,
} from './graphql';

export function StatisticsPage() {
  document.title = 'Stats';

  const { loading, error, data } = useQuery<GraphqlData>(graphqlQuery);

  if (loading) return <Loading />;
  if (error) return <Error message={error.message} />;

  if (!data) {
    return <NoDataToDisplay />;
  }

  return (
    <>
      <Title>Statistics</Title>

      <Summary data={data} />
      <EntityExamples />
      <RepeatsDistribution data={data} />
      <PieStats data={data} />
    </>
  );
}

function EntityExamples() {
  const { loading, error, data } =
    useQuery<GraphqlEntitiesData>(graphqlEntitiesQuery);

  if (loading) return <Loading />;
  if (error) return <Error message={error.message} />;

  if (!data) {
    return <NoDataToDisplay />;
  }

  return (
    <Card header="Entities example" className="margin-bottom-20">
      <div className="flex-columns flex-wrap">
        {data.entities.edges.map((entity) => (
          <div key={entity.id} className="no-wrap">
            <EntityLink entity={entity} type="button" />
          </div>
        ))}
      </div>
    </Card>
  );
}

function Summary(props: { data: GraphqlData }) {
  const { data } = props;

  const items = [
    { label: 'Database version', value: GlobalStore.data.valueStore.version },
    { label: 'Genome version', value: GlobalStore.data.valueStore.ref },
    {
      label: 'Elements count',
      value: data.statistics.elementsCount.toLocaleString(),
    },
    {
      label: 'Repeats count',
      value: data.statistics.repeatsCount.toLocaleString(),
    },
    {
      label: 'Entities count',
      value: data.statistics.entitiesCount.toLocaleString(),
    },
  ];

  return <Level items={items} />;
}

function RepeatsDistribution(props: { data: GraphqlData }) {
  const { data } = props;

  const chartData: BarDataItem[] =
    data.statistics.repeatsCountsByTypeAndSequence.map((item) => ({
      label: item.sequence,
      category: item.type,
      value: item.count,
    }));

  return (
    <Card header="Counts per chromosomes" className="margin-bottom-20">
      <BarChart
        labels={GlobalStore.data.sequencesSizes.map(
          (sequenceSize) => sequenceSize.sequence,
        )}
        data={chartData}
        legend="bottom"
        schema={elementsSchema}
      />
    </Card>
  );
}

function PieStats(props: { data: GraphqlData }) {
  const { data } = props;

  const toPieData = (countsByType: { type: string; count: number }[]) => {
    return countsByType.map((item) => ({
      label: item.type,
      value: item.count,
    }));
  };

  return (
    <Card header="Counts by type">
      <div className="flex-columns flex-columns--20">
        <PieChart
          data={toPieData(data.statistics.elementsCountsByType)}
          schema={elementsSchema}
          legend="bottom"
          title={`Elements (${data.statistics.elementsCount.toLocaleString()})`}
        />
        <PieChart
          data={toPieData(data.statistics.repeatsCountsByType)}
          schema={elementsSchema}
          legend="bottom"
          title={`Repeats (${data.statistics.repeatsCount.toLocaleString()})`}
        />
        <PieChart
          data={toPieData(data.statistics.entitiesCountsByType)}
          schema={elementsSchema}
          legend="bottom"
          title={`Entities (${data.statistics.entitiesCount.toLocaleString()})`}
        />
      </div>
    </Card>
  );
}
