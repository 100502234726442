import { gql } from '@apollo/client';
import * as Graphql from '../../../graphql';

export type Repeat = Pick<
  Graphql.Repeat,
  | 'id'
  | 'cloudId'
  | 'type'
  | 'subtype'
  | 'entityId'
  | 'sequenceBegin'
  | 'sequenceEnd'
  | 'repeatBegin'
  | 'repeatEnd'
  | 'repeatLeft'
  | 'entityBegin'
  | 'entityEnd'
  | 'entityLeft'
  | 'orientation'
>;

export interface GraphqlData {
  repeats: {
    edges: Repeat[];
  };
}

export interface GraphqlVariables {
  sequence: string;
  sequenceBeginGte: number;
  sequenceEndLte: number;
}

export const graphqlQuery = gql`
  query GetRepeats(
    $sequence: String!
    $sequenceBeginGte: Int!
    $sequenceEndLte: Int!
  ) {
    repeats(
      filter: {
        sequence: $sequence
        sequenceBeginGte: $sequenceBeginGte
        sequenceEndLte: $sequenceEndLte
      }
      pagination: { limit: 1000, page: 1 }
    ) {
      edges {
        id
        cloudId
        type
        subtype
        entityId
        sequenceBegin
        sequenceEnd
        repeatBegin
        repeatEnd
        repeatLeft
        entityBegin
        entityEnd
        entityLeft
        orientation
      }
    }
  }
`;
