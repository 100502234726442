import { Button } from '@blueprintjs/core';
import { useContext } from 'react';
import { BrowserContext } from '../../browser';
import { Controls } from '../../components/controls';

export function DownloadController() {
  const { eventBus } = useContext(BrowserContext);

  const download = () => {
    eventBus.emit('downloadImage');

    return false;
  };

  const header = (
    <Button icon="media" onClick={download} minimal>
      Download image
    </Button>
  );

  return <Controls header={header} />;
}
