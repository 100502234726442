import { useContext } from 'react';
import { Group, Line, Rect, Text } from 'react-konva';
import { getCSS } from '../../../utils/css';
import { BrowserContext } from '../../browser';
import { ChartContext } from '../../chart';
import { useModuleStateReloader } from '../../utils/hooks';
import { convertSequencePosition } from '../../utils/utils';
import { SelectionPressuresModule } from './module';

const HEADER_HEIGHT = 15;
const DATA_HEIGHT = 50;
const NAME_FONT_SIZE = 12;
const TEST_PADDING = 15;

export function SelectionPressuresChart() {
  useModuleStateReloader(SelectionPressuresModule);

  const { dataManager, setting } = useContext(BrowserContext);
  const { sequenceScale, width } = useContext(ChartContext);

  const selectionSetting = setting.get('selection');

  if (!selectionSetting?.length) {
    return null;
  }

  const selectionData = dataManager.get('selection');

  if (!selectionData) {
    return null;
  }

  const positionSetting = setting.get('position');

  const result: JSX.Element[] = [];
  let level = 0;

  for (const { meta, values } of selectionData) {
    if (!selectionSetting.includes(meta.name.toLowerCase())) {
      continue;
    }

    const scale = DATA_HEIGHT / meta.max;

    result.push(
      <Group y={(HEADER_HEIGHT + DATA_HEIGHT + TEST_PADDING) * level}>
        <Text
          text={meta.longName}
          fill={getCSS('--text-color')}
          fontSize={NAME_FONT_SIZE}
          fontFamily="monospace"
        />

        <Group y={HEADER_HEIGHT}>
          <Rect
            x={0}
            y={0}
            width={width}
            height={DATA_HEIGHT}
            fill={getCSS('--selection-pressure-chart-background')}
          />

          {values.map(({ position, value }) => {
            const x = convertSequencePosition(
              position,
              sequenceScale,
              positionSetting,
            );
            const y = (value > meta.max ? meta.max : value) * scale;

            return (
              <Line
                key={x}
                stroke={strokeByY(y)}
                strokeWidth={2}
                points={[x, DATA_HEIGHT, x, DATA_HEIGHT - y]}
              />
            );
          })}
        </Group>
      </Group>,
    );

    level += 1;
  }

  return <Group name="componentGroup">{result}</Group>;
}

function strokeByY(y: number) {
  const alpha = Math.floor((255 * y) / DATA_HEIGHT);
  const stroke =
    getCSS('--selection-pressure-chart-bar') +
    alpha.toString(16).padStart(2, '0');

  return stroke;
}
