import { useQuery } from '@apollo/client';
import {
  Button,
  ControlGroup,
  FormGroup,
  HTMLSelect,
  HTMLTable,
  InputGroup,
  Tag,
} from '@blueprintjs/core';
import { useReducer, useRef } from 'react';
import { Box } from '../../components/box';
import { EntityLink } from '../../components/entities';
import { Error } from '../../components/error';
import { Loading } from '../../components/loading';
import { NoDataToDisplay } from '../../components/noDataToDisplay';
import { Title } from '../../components/title';
import { DEFAULT_TYPE, LIMIT } from '../../config';
import { GlobalStore } from '../../globalStore';
import { SearchParams } from '../../utils/searchParams';
import { sequencePosition } from '../../utils/sequences';
import { GraphqlData, graphqlQuery, GraphqlVariables } from './graphql';
import {
  FormParams,
  formReducer,
  FormState,
  pageChange,
  resetForm,
  textInputChange,
} from '../forms/formState';
import { Pagination } from '../forms/pagination';

const reducer = formReducer<GraphqlVariables>([
  'elementId',
  'limit',
  'page',
  'search',
  'sequence',
  'sequenceBeginGte',
  'sequenceEndLte',
  'type',
  'subtype',
]);

export function EntitiesPage() {
  const searchParams = new SearchParams();

  const initialState: FormState<GraphqlVariables> = {
    search: searchParams.getString('search'),
    sequence: searchParams.getString('sequence'),
    type: searchParams.getString('type', DEFAULT_TYPE),
    subtype: searchParams.getString('subtype'),
    sequenceBeginGte: searchParams.getInt('minLength'),
    sequenceEndLte: searchParams.getInt('minLength'),
    page: searchParams.getInt('page', 1),
    limit: LIMIT,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <>
      <Form state={state} dispatch={dispatch} />
      <Table state={state} dispatch={dispatch} />
    </>
  );
}

function Form(props: FormParams<GraphqlVariables>) {
  const sequenceBeginGteRef = useRef<HTMLInputElement>(null);
  const sequenceEndLteRef = useRef<HTMLInputElement>(null);

  const applyPositions = () => {
    props.dispatch({
      action: 'setValues',
      values: {
        sequenceBeginGte:
          sequenceBeginGteRef.current &&
          parseInt(sequenceBeginGteRef.current.value),
        sequenceEndLte:
          sequenceEndLteRef.current &&
          parseInt(sequenceEndLteRef.current.value),
      },
    });
  };

  return (
    <Box key={props.state.key}>
      <FormGroup
        helperText="Enter some text to start search"
        label="Search"
        labelFor="search-input"
      >
        <InputGroup
          id="search-input"
          leftIcon="filter"
          name="search"
          placeholder="Element contains ..."
          onChange={textInputChange(props.dispatch, 500)}
          defaultValue={props.state.search ?? undefined}
          autoFocus
        />
      </FormGroup>

      <ControlGroup>
        <FormGroup
          label="Sequence"
          labelFor="sequence-input"
          contentClassName="margin-right-20"
        >
          <HTMLSelect
            id="sequence-input"
            name="sequence"
            onChange={textInputChange(props.dispatch)}
            options={['', ...GlobalStore.data.sequences]}
            defaultValue={props.state.sequence ?? undefined}
          />
        </FormGroup>

        <FormGroup
          label="Type"
          labelFor="type-input"
          contentClassName="margin-right-20"
        >
          <HTMLSelect
            id="type-input"
            name="type"
            onChange={textInputChange(props.dispatch)}
            options={['', ...GlobalStore.data.valueStore.elementTypes]}
            defaultValue={props.state.type ?? undefined}
          />
        </FormGroup>

        <FormGroup
          label="Position"
          labelInfo="(Enter one or both positions and click on submit)"
        >
          <ControlGroup>
            <InputGroup
              className="no-arrows"
              inputRef={sequenceBeginGteRef}
              placeholder="Greater than"
              type="number"
            />
            <InputGroup
              className="no-arrows"
              inputRef={sequenceEndLteRef}
              placeholder="Lesser than"
              type="number"
            />
            <Button icon="tick" intent="none" onClick={applyPositions} />
          </ControlGroup>
        </FormGroup>

        <FormGroup label="&nbsp;" style={{ marginLeft: 'auto' }}>
          <Button icon="reset" onClick={resetForm(props.dispatch)}>
            Reset
          </Button>
        </FormGroup>
      </ControlGroup>
    </Box>
  );
}

function Table(props: FormParams<GraphqlVariables>) {
  const { loading, error, data } = useQuery<GraphqlData, GraphqlVariables>(
    graphqlQuery,
    {
      variables: {
        search: props.state.search,
        sequence: props.state.sequence,
        type: props.state.type,
        subtype: props.state.subtype,
        sequenceBeginGte: props.state.sequenceBeginGte,
        sequenceEndLte: props.state.sequenceEndLte,
        limit: props.state.limit,
        page: props.state.page,
      },
    },
  );

  if (loading) return <Loading />;
  if (error) return <Error message={error.message} />;

  if (!data?.entities.edges.length) {
    return <NoDataToDisplay />;
  }

  return (
    <>
      <Title>Entities ({data.entities.pageInfo.count.toLocaleString()})</Title>

      <HTMLTable className="width-100" interactive condensed striped>
        <thead>
          <tr>
            <th>Type</th>
            <th>Name</th>
            <th>Gaps</th>
            <th>Subtype</th>
            <th>Schema</th>
            <th>Sequence</th>
          </tr>
        </thead>
        <tbody>
          {data.entities.edges.map((entity) => (
            <tr key={entity.id}>
              <td>
                <Tag minimal>{entity.type}</Tag>
              </td>
              <td>
                <EntityLink entity={entity} />
              </td>
              <td>
                {entity.gapsAbsolute} ({entity.gapsRelative}%)
              </td>
              <td>{entity.subtype}</td>
              <td>{entity.schema}</td>
              <td>{sequencePosition(entity)}</td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>

      <Pagination
        {...data?.entities.pageInfo}
        callback={pageChange(props.dispatch)}
      />
    </>
  );
}
