import { gql } from '@apollo/client';
import * as Graphql from '../../../graphql';

export type SelectionPressure = Pick<
  Graphql.SelectionPressure,
  'name' | 'longName' | 'type' | 'category' | 'max'
>;

export type SelectionPressureData = Pick<
  Graphql.SelectionPressureData,
  'position' | 'value'
>;

export interface GraphqlData {
  selectionPressures: {
    meta: SelectionPressure;
    values: SelectionPressureData[];
  }[];
}

export interface GraphqlVariables {
  sequence: string;
  positionGte: number;
  positionLte: number;
}

export const graphqlQuery = gql`
  query GetSelectionPressures(
    $sequence: String!
    $positionGte: Int!
    $positionLte: Int!
  ) {
    selectionPressures(
      filter: {
        sequence: $sequence
        positionGte: $positionGte
        positionLte: $positionLte
      }
      buckets: 400
    ) {
      meta {
        name
        longName
        type
        category
        max
      }
      values {
        position
        value
      }
    }
  }
`;
