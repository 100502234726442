import { useApolloClient } from '@apollo/client';
import { createContext, useState } from 'react';
import './browser.css';
import { BrowserWindow } from './browserWindow';
import { DataManager } from './dataManager';
import { EventBus } from './events';
import { Position } from './interfaces';
import { BaseModule } from './modules/baseModule';
import { CytobandModule } from './modules/cytoband/module';
import { DownloadModule } from './modules/download/module';
import { Entity } from './modules/entities/graphql';
import { EntitiesModule } from './modules/entities/module';
import { EntityModule } from './modules/entity/module';
import { GenesModule } from './modules/genes/module';
import { RepeatsModule } from './modules/repeats/module';
import { SelectionPressuresModule } from './modules/selectionPressures/module';
import { SequenceSizesModule } from './modules/sequenceSizes/module';
import { SequenceTrackModule } from './modules/sequenceTrack/module';
import { TopInfoModule } from './modules/topInfo/module';
import { ZoomModule } from './modules/zoom/module';
import { Setting } from './settings';

export interface BrowserProps {
  entity?: Entity;
  sequence: string;
  sequenceEnd: number;
  sequenceBegin: number;
  withPositionSelection?: boolean;
  minimal?: boolean;
  settings?: Record<string, unknown>;
}

export interface IBrowserContext {
  modules: BaseModule[];
  eventBus: EventBus;
  setting: Setting;
  dataManager: DataManager;
}

export const BrowserContext = createContext<IBrowserContext>({} as any);

export function Browser(props: BrowserProps) {
  const client = useApolloClient();

  const [{ dataManager, eventBus, setting, modules }] = useState(() => {
    const position: Position = {
      sequence: props.sequence,
      sequenceBegin: props.sequenceBegin,
      sequenceEnd: props.sequenceEnd,
    };

    const eventBus = new EventBus();
    const setting = new Setting(eventBus, { position, ...props.settings });
    const dataManager = new DataManager(client, { entity: props.entity });

    const modules: BaseModule[] = [
      new EntitiesModule(eventBus, setting, dataManager),
      new RepeatsModule(eventBus, setting, dataManager),
      new TopInfoModule(eventBus, setting, dataManager),
      new CytobandModule(eventBus, setting, dataManager),
      new GenesModule(eventBus, setting, dataManager),
      new DownloadModule(eventBus, setting, dataManager),
      new EntityModule(eventBus, setting, dataManager),
      new SequenceSizesModule(eventBus, setting, dataManager),
      new SequenceTrackModule(eventBus, setting, dataManager),
      new ZoomModule(eventBus, setting, dataManager),
      new SelectionPressuresModule(eventBus, setting, dataManager),
    ];

    setting.loadSearchParams();

    return {
      eventBus,
      modules,
      setting,
      dataManager,
    };
  });

  const ctx: IBrowserContext = {
    dataManager,
    eventBus,
    setting,
    modules,
  };

  return (
    <div className="browser">
      <BrowserContext.Provider value={ctx}>
        <BrowserWindow
          minimal={props.minimal}
          withPositionSelection={props.withPositionSelection}
        />
      </BrowserContext.Provider>
    </div>
  );
}
