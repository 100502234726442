import { ElementReference } from '../graphql';
import { ExternalLink } from './links';

interface ReferencesProps {
  references: ElementReference[];
}

export function ElementReferences(props: ReferencesProps) {
  return (
    <ul>
      {props.references.map((reference) => (
        <li key={reference.title}>
          {reference.title}
          <em>{reference.authors}</em>

          <ExternalLink
            href={`https://www.ncbi.nlm.nih.gov/pubmed/?term=${decodeURI(
              reference.location
            )}`}
          >
            PubMed
          </ExternalLink>
        </li>
      ))}
    </ul>
  );
}
