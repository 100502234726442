import { useQuery } from '@apollo/client';
import { Callout } from '@blueprintjs/core';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Error } from '../components/error';
import { Loading } from '../components/loading';
import { setGlobalData } from '../globalStore';
import { Themed } from '../theme';
import './app.css';
import { Content } from './content';
import { GraphqlData, graphqlQuery } from './graphql';
import { Header } from './header';
import { useTracking } from './tracking';

export function App() {
  useTracking();

  const { loading, error, data } = useQuery<GraphqlData>(graphqlQuery);

  if (loading) return <Loading />;
  if (error) return <Error message={error.message} />;

  if (!data) {
    return (
      <Callout intent="warning" icon="warning-sign">
        Cannot obtain data
      </Callout>
    );
  }

  setGlobalData({
    ...data,
    representativeEntity: data.entities.edges[0],
    sequences: data.sequencesSizes.map(({ sequence }) => sequence),
  });

  return (
    <Themed>
      <ScrollToTop />
      <Header />
      <Content />
    </Themed>
  );
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
