import { BaseModule } from '../baseModule';
import { ZoomController } from './controller';

export class ZoomModule extends BaseModule {
  initialize() {
    this.controllerPosition = 1;
    this.changeState('OK');
  }

  controllerComponent() {
    return ZoomController;
  }
}
