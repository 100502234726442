import { useContext } from 'react';
import { Group, Line, Rect } from 'react-konva';
import { getCSS } from '../../../utils/css';
import { BrowserContext } from '../../browser';
import { ChartContext } from '../../chart';
import { Position } from '../../interfaces';
import { useModuleStateReloader } from '../../utils/hooks';
import { SequenceSizesModule } from '../sequenceSizes/module';
import { Cytoband } from './graphql';
import { CytobandModule } from './module';

const SKIP_STAINS = ['gneg'];
const HEIGHT = 15;
const CENTROMERIC_STAIN = 'acen';
const RANGE_OVERLAP = 4;

export function CytobandChart() {
  useModuleStateReloader(CytobandModule, SequenceSizesModule);
  const { width } = useContext(ChartContext);
  const { setting, dataManager } = useContext(BrowserContext);

  const position = setting.get('position');
  const cytobands = dataManager.get('cytoband');
  const sequenceSize = dataManager.get('sequenceSize');

  if (!setting.get('cytoband') || !cytobands || !sequenceSize) {
    return null;
  }

  const scale = width / sequenceSize.size;

  const result = [...items(cytobands, width, scale), range(position, scale)];

  return (
    <Group name="componentGroup">
      <Group y={RANGE_OVERLAP}>{result}</Group>
    </Group>
  );
}

function items(cytobands: Cytoband[], chartWidth: number, scale: number) {
  let cenBegin: number;
  let cenEnd: number;

  const result: JSX.Element[] = [];

  cytobands.forEach((item) => {
    if (SKIP_STAINS.includes(item.stain)) {
      return;
    }

    const width = (item.sequenceEnd - item.sequenceBegin) * scale;
    const x = item.sequenceBegin * scale;

    if (item.stain === CENTROMERIC_STAIN) {
      const polygons = [];

      if (item.name.startsWith('p')) {
        cenBegin = x;
        polygons.push(x, 0);
        polygons.push(x + width, HEIGHT / 2);
        polygons.push(x, HEIGHT);
      } else {
        cenEnd = x + width;
        polygons.push(x + width, 0);
        polygons.push(x, HEIGHT / 2);
        polygons.push(x + width, HEIGHT);
      }

      result.push(
        <Line
          points={polygons}
          fill={getCSS(`--entity-chart-cytoband-stain-${item.stain}`)}
          closed
        />,
      );
    } else {
      result.push(
        <Rect
          x={x}
          width={width}
          height={HEIGHT}
          fill={getCSS(`--entity-chart-cytoband-stain-${item.stain}`)}
        />,
      );
    }

    //
    // Render border
    //
    if (cenBegin && cenEnd) {
      const polylinesLeft = [];
      const polylinesRight = [];

      polylinesLeft.push(cenBegin, 0);
      polylinesLeft.push(0, 0);
      polylinesLeft.push(0, HEIGHT);
      polylinesLeft.push(cenBegin, HEIGHT);

      polylinesRight.push(cenEnd, 0);
      polylinesRight.push(chartWidth, 0);
      polylinesRight.push(chartWidth, HEIGHT);
      polylinesRight.push(cenEnd, HEIGHT);

      result.push(
        <Line
          points={polylinesLeft}
          stroke={getCSS('--entity-chart-cytoband-border')}
          strokeWidth={0.5}
        />,
      );

      result.push(
        <Line
          points={polylinesRight}
          stroke={getCSS('--entity-chart-cytoband-border')}
          strokeWidth={0.5}
        />,
      );
    }
  });

  return result;
}

function range(position: Position, scale: number) {
  const width = (position.sequenceEnd - position.sequenceBegin) * scale;
  const x = position.sequenceBegin * scale;

  return (
    <Rect
      x={x}
      y={-2}
      width={width}
      height={HEIGHT + 4}
      strokeWidth={5}
      stroke={getCSS('--entity-chart-cytoband-range')}
    />
  );
}
