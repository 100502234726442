import { useQuery } from '@apollo/client';
import {
  Button,
  Classes,
  Icon,
  MenuItem,
  Spinner,
  SpinnerSize,
} from '@blueprintjs/core';
import { IItemRendererProps, Omnibar } from '@blueprintjs/select';
import { debounce } from 'lodash';
import { useContext, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { trackEvent } from '../../app/tracking';
import { themeClass, ThemeContext } from '../../theme';
import { EntityBar } from '../entities';
import { GraphqlData, graphqlQuery, SearchItem } from './graphql';

const SEARCH_LIMIT = 5;

const Search = Omnibar.ofType<SearchItem>();

interface SearchState {
  opened: boolean;
  searching: boolean;
  error?: boolean;
  query?: string;
  data: SearchItem[];
}

type SearchAction =
  | { type: 'opened'; value: boolean }
  | { type: 'queryChange'; query: string }
  | { type: 'error' }
  | { type: 'newData'; data: SearchItem[] };

const initialState: SearchState = {
  searching: false,
  data: [],
  opened: false,
  error: false,
};

function searchReducer(state: SearchState, action: SearchAction): SearchState {
  switch (action.type) {
    case 'opened':
      trackEvent({ category: 'OmniSearch', action: 'opened' });
      return { ...initialState, opened: action.value };

    case 'queryChange':
      trackEvent({ category: 'OmniSearch', action: 'query' });
      return { ...state, searching: !!action.query, query: action.query };

    case 'error':
      return { ...state, searching: false, query: undefined, error: true };

    case 'newData':
      return {
        ...state,
        searching: false,
        query: undefined,
        data: action.data,
      };

    default:
      throw new Error('Unknown action');
  }
}

export function OmniSearch() {
  const { theme } = useContext(ThemeContext);
  const [searchState, searchDispatch] = useReducer(searchReducer, initialState);
  const navigate = useNavigate();

  const { error, data } = useQuery<GraphqlData>(graphqlQuery, {
    skip: !searchState.query,
    variables: { query: searchState.query, limit: SEARCH_LIMIT },
  });

  if (error) {
    searchDispatch({ type: 'error' });
  }

  if (data) {
    searchDispatch({ type: 'newData', data: data.search });
  }

  const onQueryChange = (query: string) => {
    searchDispatch({ type: 'queryChange', query });
  };

  const inputIcon = () => {
    if (searchState.searching) {
      return <Spinner size={SpinnerSize.SMALL} />;
    } else if (searchState.error) {
      return <Button icon="warning-sign" disabled />;
    } else {
      return;
    }
  };

  const itemRenderer = (item: SearchItem, options: IItemRendererProps) => {
    let text: JSX.Element | string | null = null;
    let subText: JSX.Element | string | null = null;
    let id: number | undefined;

    switch (item.type) {
      case 'Element': {
        id = item.element.id;

        text = <div>{item.element.name}</div>;
        subText = (
          <div className={Classes.TEXT_MUTED}>
            Element <Icon icon="caret-right" /> {item.element.type}{' '}
            <Icon icon="caret-right" /> #{item.element.id}
          </div>
        );
        break;
      }

      case 'Entity': {
        id = item.entity.id;

        text = (
          <div>
            <EntityBar entity={item.entity} />{' '}
            {item.entity.repeatNames?.join(', ')}
          </div>
        );
        subText = (
          <div className={Classes.TEXT_MUTED}>
            Entity <Icon icon="caret-right" /> {item.entity.type}{' '}
            <Icon icon="caret-right" /> #{item.entity.id}
          </div>
        );
        break;
      }
    }

    return (
      <MenuItem
        active={options.modifiers.active}
        disabled={options.modifiers.disabled}
        label={`#${id}`}
        onClick={options.handleClick}
        text={
          <div>
            {text}
            {subText}
          </div>
        }
      />
    );
  };

  const onItemSelect = (item: SearchItem) => {
    searchDispatch({ type: 'opened', value: false });

    switch (item.type) {
      case 'Element':
        navigate(`/elements/${item.element.id}`);
        break;

      case 'Entity':
        navigate(`/entities/${item.entity.id}`);

        break;
    }
  };

  return (
    <>
      <Button
        icon="search"
        onClick={() => searchDispatch({ type: 'opened', value: true })}
        minimal
      />

      {/* This approach completely reset values (instead of `isOpen`) */}
      {searchState.opened && (
        <Search
          className={themeClass(theme)}
          items={searchState.data}
          inputProps={{
            placeholder: 'Search ... (enter at least 3 characters)',
            rightElement: inputIcon(),
          }}
          itemRenderer={itemRenderer}
          onItemSelect={onItemSelect}
          onQueryChange={debounce(onQueryChange, 500)}
          isOpen={true}
          onClose={() => searchDispatch({ type: 'opened', value: false })}
        />
      )}
    </>
  );
}
