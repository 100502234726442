import { pull } from 'lodash';
import { URLSearchParams } from 'url';
import { SettingValues } from './settings';

type Callback<T = any> = (_: T) => void;

interface Channels {
  loadSearchParams: URLSearchParams;
  setSearchParams: URLSearchParams;
  moduleStateChanged: string;
  browserMounted: void;
  downloadImage: void;
  reloadChart: void;
  settingChanged: (keyof SettingValues)[];
}

type Keys = keyof Channels;

export class EventBus {
  subscribers: Record<string, Callback[]> = {};

  reset() {
    Object.keys(this.subscribers).forEach((key) => {
      delete this.subscribers[key];
    });
  }

  on<T extends Keys>(channel: T, callback: Callback<Channels[T]>) {
    if (!this.subscribers[channel]) {
      this.subscribers[channel] = [];
    }

    if (isDev()) {
      console.log(`[EventBus] on ${channel}`);
    }

    this.subscribers[channel].push(callback);
  }

  off(channel: Keys, callback: Callback) {
    if (!this.subscribers[channel]) {
      return;
    }

    if (isDev()) {
      console.log(`[EventBus] off ${channel}`);
    }

    pull(this.subscribers[channel], callback);
  }

  emit<T extends Keys, V = Channels[T]>(
    channel: T,
    ...args: V extends void ? [arg?: void] : [arg: V]
  ) {
    const [arg] = args;
    const subscribers = this.subscribers[channel];

    if (isDev()) {
      console.log(`[EventBus] emit ${channel} [${args.join(', ')}]`);
    }

    if (subscribers) {
      for (const subscriber of subscribers) {
        subscriber(arg);
      }
    }
  }
}

function isDev() {
  return process.env.NODE_ENV === 'development';
}
