interface RepeatLengthParams {
  orientation: string;
  repeatBegin: number;
  repeatEnd: number;
  repeatLeft: number;
}

interface SequenceLengthParams {
  sequenceBegin: number;
  sequenceEnd: number;
}

export function repeatLength(item: RepeatLengthParams): number {
  if (item.orientation === 'F') {
    return item.repeatEnd - item.repeatBegin;
  } else {
    return item.repeatEnd - item.repeatLeft;
  }
}

export function sequenceLength(item: SequenceLengthParams) {
  return item.sequenceEnd - item.sequenceBegin + 1;
}

interface SequenceNonEntitySizeParams extends SequenceLengthParams {
  length: number;
}

export function sequenceNonEntitySize(item: SequenceNonEntitySizeParams) {
  return sequenceLength(item) - item.length;
}
