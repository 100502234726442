import { Classes } from '@blueprintjs/core';
import { createContext, useEffect, useState } from 'react';
import { PropsWithChildren } from 'react';

type PossibleThemes = 'light' | 'dark';

interface ThemeContextType {
  theme: PossibleThemes;
  toggleTheme: () => void;
}

export const ThemeContext = createContext<ThemeContextType>({
  theme: 'light',
  toggleTheme: () => {
    return;
  },
});

function setRootDataset(theme: PossibleThemes) {
  const dataset = document?.querySelector('html')?.dataset;

  if (dataset) {
    dataset.theme = theme;
  }
}

export function themeClass(theme: string) {
  return theme === 'light' ? '' : Classes.DARK;
}

export function Themed(props: PropsWithChildren<unknown>) {
  let defaultTheme = localStorage.getItem('theme');

  if (defaultTheme !== 'dark') {
    defaultTheme = 'light';
  }

  const [theme, setTheme] = useState(defaultTheme as PossibleThemes);

  setRootDataset(theme);

  useEffect(() => {
    localStorage.setItem('theme', theme);
    setRootDataset(theme);
  }, [theme]);

  const toggleTheme = () => {
    if (theme === 'light') {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <div className={themeClass(theme)}>{props.children}</div>
    </ThemeContext.Provider>
  );
}
