import { useQuery } from '@apollo/client';
import { H3, HTMLTable } from '@blueprintjs/core';
import { Box } from '../../components/box';
import { Error } from '../../components/error';
import { ExternalLink } from '../../components/links';
import { Loading } from '../../components/loading';
import { Title } from '../../components/title';
import { SERVER_URL } from '../../config';
import { File } from '../../graphql';
import { humanSize } from '../../utils/parsers';
import { getPackageName } from './formatters';
import { GraphqlData, graphqlQuery } from './graphql';

export function DownloadsPage() {
  const { loading, error, data } = useQuery<GraphqlData>(graphqlQuery);

  if (loading) return <Loading />;
  if (error) return <Error message={error.message} />;

  return (
    <>
      <Title>Downloads</Title>

      {data?.files.packages && <Packages packages={data.files.packages} />}
    </>
  );
}

function Packages({ packages }: { packages: File[] }) {
  return (
    <Box>
      <H3>Packages</H3>

      <HTMLTable className="width-100" interactive condensed striped>
        <thead>
          <tr>
            <th>Name</th>
            <th>Size</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
          {packages.map((file) => (
            <tr key={file.name}>
              <td>{getPackageName(file)}</td>
              <td>{humanSize(file.size, { fixed: 0, spaceBetween: true })}b</td>
              <td>
                <ExternalLink href={`${SERVER_URL}${file.url}`}>
                  Download {file.name}
                </ExternalLink>
              </td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>
    </Box>
  );
}
