import { arrayToParam, paramToArray } from '../../utils/searchParams';
import { BaseModule } from '../baseModule';
import { EntitiesChart } from './chart';
import { EntitiesController } from './controller';
import { GraphqlData, graphqlQuery, GraphqlVariables } from './graphql';

export const key = 'entities';

export class EntitiesModule extends BaseModule {
  initialize() {
    this.chartPosition = 3;
    this.controllerPosition = 5;

    this.eventBus.on('loadSearchParams', this.loadSearchParams.bind(this));
    this.eventBus.on('setSearchParams', this.setSearchParams.bind(this));
    this.eventBus.on('settingChanged', this.settingChanged.bind(this));
    this.eventBus.on('browserMounted', this.browserMounted.bind(this));
  }

  chartComponent() {
    return EntitiesChart;
  }

  controllerComponent() {
    return EntitiesController;
  }

  private browserMounted() {
    this.loadDataIfNecessary();
  }

  private async loadData(): Promise<void> {
    this.changeState('LOADING');

    const { sequence, sequenceBegin, sequenceEnd } =
      this.setting.get('position');

    return this.dataManager
      .load<GraphqlData, GraphqlVariables>(
        key,
        graphqlQuery,
        {
          sequence,
          sequenceBeginGte: sequenceBegin,
          sequenceEndLte: sequenceEnd,
        },
        (data) => data.entities.edges,
      )
      .then(() => this.changeState('OK'))
      .catch(() => this.changeState('ERROR'));
  }

  private loadSearchParams(params: URLSearchParams) {
    const param = params.get(key);

    this.setting.set('entities', paramToArray(param));
  }

  private setSearchParams(params: URLSearchParams) {
    const settingValue = this.setting.get('entities');

    params.set(key, arrayToParam(settingValue));
  }

  private settingChanged(keys: string[]) {
    if (keys.includes(key) || keys.includes('position')) {
      this.loadDataIfNecessary();
    }
  }

  private loadDataIfNecessary() {
    const value = this.setting.get('entities');

    if (value?.length) {
      this.loadData();
    } else {
      this.changeState('OK');
    }
  }
}
