import { gql } from '@apollo/client';
import * as Graphql from '../../../graphql';

export type SequenceSize = Pick<Graphql.SequenceSize, 'sequence' | 'size'>;

export interface GraphqlData {
  sequenceSize: SequenceSize;
}

export interface GraphqlVariables {
  sequence: string;
}

export const graphqlQuery = gql`
  query GetSequenceSize($sequence: String!) {
    sequenceSize(sequence: $sequence) {
      size
      sequence
    }
  }
`;
