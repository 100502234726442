import { useContext, useEffect, useState } from 'react';
import { Error } from '../components/error';
import { BrowserContext } from './browser';
import { Chart } from './chart';
import { Controller } from './controller';
import { Loader } from './loader';
import { PositionSelection } from './positionSelection';
import { usePositionReloader } from './utils/hooks';

interface BrowserWindowProps {
  minimal?: boolean;
  withPositionSelection?: boolean;
}

export function BrowserWindow(props: BrowserWindowProps) {
  usePositionReloader();

  const { eventBus, modules } = useContext(BrowserContext);
  const [error, setError] = useState(false);

  useEffect(() => {
    const moduleStateChanged = () => {
      if (modules.some((mod) => mod.state === 'ERROR')) {
        setError(true);
      }
    };

    eventBus.on('moduleStateChanged', moduleStateChanged);
    eventBus.emit('browserMounted');

    return () => {
      eventBus.off('moduleStateChanged', moduleStateChanged);
    };
  }, []);

  if (error) {
    return <Error message="Sorry but something happen" />;
  }

  return (
    <>
      <Loader />
      {props.withPositionSelection && <PositionSelection />}
      <Chart />
      {!props.minimal && <Controller />}
    </>
  );
}
