import Konva from 'konva';
import { Position } from '../interfaces';

export function convertSequencePosition(
  value: number,
  sequenceScale: number,
  position: Position,
): number {
  return (value - position.sequenceBegin) * sequenceScale;
}

export function textWidth(text: string, fontSize: number) {
  const textItem = new Konva.Text({
    text,
    fontSize,
  });

  return textItem.width();
}
