import { gql } from '@apollo/client';
import * as Graphql from '../../../graphql';

export type Cytoband = Pick<
  Graphql.Cytoband,
  'id' | 'sequence' | 'sequenceBegin' | 'sequenceEnd' | 'name' | 'stain'
>;

export interface GraphqlData {
  cytoband: Cytoband[];
}

export interface GraphqlVariables {
  sequence: string;
}

export const graphqlQuery = gql`
  query GetCytoband($sequence: String!) {
    cytoband(
      filter: { sequence: $sequence }
      pagination: { limit: 1000, page: 1 }
    ) {
      id
      sequence
      sequenceBegin
      sequenceEnd
      name
      stain
    }
  }
`;
