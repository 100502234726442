import { PropsWithChildren } from 'react';
import './card.css';

interface CardProps {
  header: string | JSX.Element;
  className?: string;
}

export function Card(props: PropsWithChildren<CardProps>) {
  return (
    <div className={`card ${props.className}`}>
      <div className="card__header">{props.header}</div>
      <div className="card__body">{props.children}</div>
    </div>
  );
}
