import { Switch } from '@blueprintjs/core';
import { ChangeEvent, useContext } from 'react';
import { BrowserContext } from '../../browser';
import { Controls } from '../../components/controls';

export function GenesController() {
  const { eventBus, setting } = useContext(BrowserContext);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setting.set('genes', event.target.checked);
    eventBus.emit('settingChanged', ['genes']);
  };

  const header = (
    <Switch
      labelElement={'Genes'}
      onChange={onChange}
      defaultChecked={setting.get('genes')}
    />
  );

  return <Controls header={header} />;
}
