export type ChartSchema = (value: string) => string;

const classic20 = [
  '#1f77b4',
  '#aec7e8',
  '#ff7f0e',
  '#ffbb78',
  '#2ca02c',
  '#98df8a',
  '#d62728',
  '#ff9896',
  '#9467bd',
  '#c5b0d5',
  '#8c564b',
  '#c49c94',
  '#e377c2',
  '#f7b6d2',
  '#7f7f7f',
  '#c7c7c7',
  '#bcbd22',
  '#dbdb8d',
  '#17becf',
  '#9edae5',
];

export function createSchema(schema = 'tableau.Classic20'): ChartSchema {
  const memo: Record<string, string> = {};
  let currentIndex = -1;

  return (value: string) => {
    if (memo[value]) {
      return memo[value];
    }

    currentIndex = (currentIndex + 1) % classic20.length;

    memo[value] = classic20[currentIndex];
    return memo[value];
  };
}

export const elementsSchema = createSchema('tableau.Classic20');

// Just a little hack that ERV will be first color
elementsSchema('ERV');
