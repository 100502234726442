import { useContext, useEffect, useState } from 'react';
import { BrowserContext } from '../browser';
import { BaseModule } from '../modules/baseModule';
import { SettingValues } from '../settings';

export function useModuleStateReloader(...modules: typeof BaseModule[]) {
  const { eventBus } = useContext(BrowserContext);
  const [timestamp, setTimestamp] = useState(0);

  useEffect(() => {
    const callback = (name: string) => {
      for (const mod of modules) {
        if (name === mod.name) {
          setTimestamp(Date.now());
        }
      }
    };

    eventBus.on('moduleStateChanged', callback);

    return () => {
      eventBus.off('moduleStateChanged', callback);
    };
  }, []);

  return [timestamp];
}

export function usePositionReloader() {
  const { eventBus } = useContext(BrowserContext);
  const [timestamp, setTimestamp] = useState(0);

  useEffect(() => {
    const settingChanged = (keys: (keyof SettingValues)[]) => {
      if (keys.includes('position')) {
        setTimestamp(Date.now());
      }
    };
    eventBus.on('settingChanged', settingChanged);

    return () => {
      eventBus.off('settingChanged', settingChanged);
    };
  }, []);

  return [timestamp];
}
