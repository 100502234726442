import { Icon } from '@blueprintjs/core';
import './email.css';

interface EmailProps {
  name: string;
  email: string;
}

export function Email(props: EmailProps) {
  const [before, after] = props.email.split('@', 2);

  return (
    <div className="email">
      {props.name}
      <span className="email__address">
        (
        <Icon className="email__envelope" icon="envelope" />
        {before}
        <span className="email__at"></span>
        {after})
      </span>
    </div>
  );
}
