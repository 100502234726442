import { Chart, ChartDataset, LayoutPosition, LegendOptions } from 'chart.js';
import { DeepPartial } from 'chart.js/types/utils';
import './base';
import { renderChart } from './base';
import { ChartSchema, createSchema, elementsSchema } from './schema';

export interface DataItem {
  label: string;
  value: number;
  category?: string;
}

interface BarChartOptions {
  labels: string[];
  data: DataItem[];
  legend?: false | LayoutPosition;
  schema?: ChartSchema;
  title?: string;
}

export function BarChart(props: BarChartOptions) {
  return renderChart({
    title: props.title,
    draw: (context) => draw(context, props),
  });
}

function draw(context: CanvasRenderingContext2D, params: BarChartOptions) {
  const { data, labels } = params;

  const dataByCategory: Record<string, ChartDataset<'bar'>> = {};

  data.sort((item1, item2) => item2.value - item1.value);

  const chartSchema = params.schema || createSchema('tableau.Classic20');

  data.forEach((item) => {
    const category = item.category || '_';

    if (!dataByCategory[category]) {
      dataByCategory[category] = {
        label: category,
        data: [],

        backgroundColor: (context) => {
          if (context.dataset?.label) {
            return chartSchema(context.dataset?.label);
          } else {
            return elementsSchema('unknown');
          }
        },
      };
    }

    dataByCategory[category].data[labels.indexOf(item.label)] = item.value;
  });

  const textColor = getComputedStyle(document.documentElement).getPropertyValue(
    '--text-color',
  );

  const legend: DeepPartial<LegendOptions<'bar'>> = {
    labels: {
      color: textColor,
    },
  };

  if (params.legend) {
    legend.position = params.legend;
  } else {
    legend.display = false;
  }

  return new Chart(context, {
    type: 'bar',
    data: {
      labels,
      datasets: Object.values(dataByCategory),
    },
    options: {
      plugins: {
        legend,
        tooltip: {
          mode: 'index',
          intersect: true,
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            color: `${textColor}10`,
          },
        },
        y: {
          stacked: true,
          grid: {
            color: `${textColor}10`,
          },
        },
      },
    },
  });
}
