import { Button, Classes } from '@blueprintjs/core';
import { useContext } from 'react';
import { sequencePosition } from '../../../utils/sequences';
import { BrowserContext } from '../../browser';
import { Controls } from '../../components/controls';
import { Position } from '../../interfaces';

export function ZoomController() {
  const { setting, eventBus } = useContext(BrowserContext);

  const position = setting.get('position');

  const change = (by: number) => {
    const newPosition: Position = {
      sequence: position.sequence,
      sequenceBegin: Math.max(0, position.sequenceBegin - by),
      sequenceEnd: Math.max(0, position.sequenceEnd + by),
    };

    setting.set('position', newPosition);
    eventBus.emit('settingChanged', ['position']);
  };

  const range = position.sequenceEnd - position.sequenceBegin;

  const header = (
    <div key="header" className="flex-space-between">
      <span>
        <Button icon="zoom-out" onClick={() => change(1000)}>
          2Kb
        </Button>
        <Button icon="zoom-out" onClick={() => change(5000)}>
          10Kb
        </Button>
        <Button icon="zoom-out" onClick={() => change(10000)}>
          20Kb
        </Button>
      </span>

      <span className={Classes.MONOSPACE_TEXT}>
        ({sequencePosition(position, true)})
      </span>

      <span>
        {range > 2000 && (
          <Button icon="zoom-in" onClick={() => change(-1000)}>
            2Kb
          </Button>
        )}
        {range > 10_000 && (
          <Button icon="zoom-in" onClick={() => change(-5_000)}>
            10Kb
          </Button>
        )}
        {range > 20_000 && (
          <Button icon="zoom-in" onClick={() => change(-10_000)}>
            20Kb
          </Button>
        )}
      </span>
    </div>
  );

  return <Controls header={header} />;
}
