import { BaseModule } from '../baseModule';
import { TopInfoChart } from './chart';

export class TopInfoModule extends BaseModule {
  initialize() {
    this.chartPosition = 1;
    this.changeState('OK');
  }

  chartComponent() {
    return TopInfoChart;
  }
}
