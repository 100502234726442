import { Browser, BrowserProps } from '../browser/browser';

const defaults: BrowserProps = {
  sequence: 'chr1',
  sequenceBegin: 0,
  sequenceEnd: 100000,
  withPositionSelection: true,
  settings: {
    cytoband: true,
    entities: ['erv'],
  },
};

export function BrowserPage() {
  document.title = 'Browser';

  return <Browser {...defaults} />;
}
