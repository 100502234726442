export function booleanToParam(value?: boolean): string {
  return value ? '1' : '0';
}

export function paramToBoolean(value: string | null): boolean {
  if (!value) {
    return false;
  } else if (value === '1' || value === 't' || value === 'true') {
    return true;
  } else {
    return false;
  }
}

export function arrayToParam(value?: string[]): string {
  return value ? value.join('|') : '';
}

export function paramToArray(value: string | null): string[] {
  return value ? value.split('|') : [];
}
