import { Route, Routes } from 'react-router-dom';
import { routes } from '../routes';

export function Content() {
  return (
    <div className="main-wrapper">
      <div className="main">
        <Routes>
          {routes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<route.component />}

              // end={route.end}
              // render={({ location }) => <route.component key={location.key} />}
              // component={route.component}
            />
          ))}
        </Routes>
      </div>
    </div>
  );
}
