import { useQuery } from '@apollo/client';
import { Classes, HTMLTable, Icon, Tag } from '@blueprintjs/core';
import { uniqBy } from 'lodash';
import { useParams } from 'react-router-dom';
import { Browser } from '../../browser/browser';
import { Box } from '../../components/box';
import { Cigar } from '../../components/cigar';
import { EntityBar } from '../../components/entities';
import { Error } from '../../components/error';
import { Loading } from '../../components/loading';
import { NoDataToDisplay } from '../../components/noDataToDisplay';
import { RepeatError } from '../../components/repeatError';
import { TableDetails, TableDetailsData } from '../../components/tableDetails';
import { Title } from '../../components/title';
import { ENTITY_BROWSER_PADDING } from '../../config';
import { sequenceLength, sequenceNonEntitySize } from '../../utils/lengths';
import { repeatNames } from '../../utils/repeats';
import { sequencePosition } from '../../utils/sequences';
import { ElementDetail } from './elementDetail';
import { GraphqlData, graphqlQuery } from './graphql';

export function EntityPage() {
  const { id } = useParams<{ id: string }>();

  const { loading, error, data } = useQuery<GraphqlData>(graphqlQuery, {
    variables: { id: parseInt(id!) },
  });

  if (loading) return <Loading />;
  if (error) return <Error message={error.message} />;

  if (!data?.entity) {
    return <NoDataToDisplay />;
  }

  const { entity } = data;

  const tableDataLeft: TableDetailsData = [
    {
      label: 'Consensus length',
      value: entity.length,
      tooltip: 'Whole length of the original entity in bp',
    },
    {
      label: 'Consensus missing',
      value: `${entity.gapsAbsolute}b (${entity.gapsRelative}%)`,
      tooltip: 'How many bases are missing',
    },
    {
      label: 'Length on sequence',
      value: `${sequenceLength(entity)}b (foreign: ${sequenceNonEntitySize(
        entity,
      )})`,
      tooltip:
        'Difference between starting point and end point on the sequence',
    },
    {
      label: 'Elements',
      value: repeatNames(entity.repeats),
      tooltip: 'What elements this entity contains',
    },
    {
      label: 'Type',
      value: entity.type,
      tooltip: 'Type of the entity (ERV, LINE, SINE, ...)',
    },
    {
      label: 'Subtype',
      value: `${entity.subtype} (schema: ${entity.schema})`,
      tooltip: 'For example (L: there are only LTRs) (LI: LTR and interal)',
    },
    {
      label: 'Sequence',
      value: sequencePosition(entity),
    },
  ];

  const tableDataRight: TableDetailsData = [
    {
      label: 'Elements',
      value: (
        <>
          {uniqBy(entity.repeats, 'elementId').map((repeat) => {
            return (
              <>
                <Tag className="margin-right-20" minimal>
                  <ElementDetail id={repeat.elementId} name={repeat.name} />{' '}
                </Tag>
              </>
            );
          })}
        </>
      ),
    },
  ];

  return (
    <>
      <Title>{data.entity.name}</Title>

      <div
        className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED} ${Classes.MONOSPACE_TEXT}`}
      >
        <Icon icon="social-media" className="margin-right-5" />
        {data.entity.cloudId}
      </div>

      <Box>
        <div className="flex-columns flex-columns--20">
          <div>
            <TableDetails data={tableDataLeft} />
          </div>
          <div>
            <TableDetails data={tableDataRight} />
          </div>
        </div>
      </Box>

      <Browser
        entity={entity}
        sequence={entity.sequence}
        sequenceBegin={Math.max(
          entity.sequenceBegin - ENTITY_BROWSER_PADDING,
          0,
        )}
        sequenceEnd={entity.sequenceEnd + ENTITY_BROWSER_PADDING}
      />

      <HTMLTable className="width-100" interactive condensed striped>
        <thead>
          <tr>
            <th>Error</th>
            <th>Name</th>
            <th>Position</th>
            <th>Location</th>
            <th>CIGAR</th>
          </tr>
        </thead>
        <tbody>
          {entity.repeats.map((repeat) => {
            const barEntity = {
              id: entity.id,
              length: entity.length,
              repeats: [repeat],
            };

            return (
              <tr key={repeat.id}>
                <td>
                  <RepeatError repeat={repeat} />
                </td>
                <td className="no-wrap">
                  <ElementDetail id={repeat.elementId} name={repeat.name} />
                </td>
                <td className="no-wrap">{sequencePosition(repeat)}</td>
                <td>
                  <EntityBar entity={barEntity} />
                </td>
                <td
                  className={`${Classes.MONOSPACE_TEXT} ${Classes.TEXT_SMALL} word-break-all`}
                >
                  {repeat.cigar && <Cigar cigar={repeat.cigar} />}
                </td>
              </tr>
            );
          })}
        </tbody>
      </HTMLTable>
    </>
  );
}
