interface SequencePositionParams {
  sequence: string;
  sequenceBegin: number;
  sequenceEnd: number;
  orientation?: string;
}

export function sequencePosition(
  params: SequencePositionParams,
  format = false,
) {
  const sequenceBegin = format
    ? params.sequenceBegin.toLocaleString()
    : params.sequenceBegin;
  const sequenceEnd = format
    ? params.sequenceEnd.toLocaleString()
    : params.sequenceEnd;

  let result = `${params.sequence}: ${sequenceBegin} - ${sequenceEnd}`;

  if (params.orientation) {
    result += ` (${params.orientation})`;
  }

  return result;
}
